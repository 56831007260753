import React, { Component } from 'react';
import { MultiStepIndicator } from "monday-ui-react-core";
import { Button } from "monday-ui-react-core";
import { RadioButton } from "monday-ui-react-core";
import { Checkbox } from "monday-ui-react-core";
import { Dropdown } from "monday-ui-react-core";
import { Flex } from "monday-ui-react-core";
import { Link, Chips } from "monday-ui-react-core";
import { Box } from "monday-ui-react-core";
import { TextField } from "monday-ui-react-core";
import { Tooltip, Tipseen, TipseenContent } from "monday-ui-react-core";
import { SplitButton, SplitButtonMenu, MenuItem, ExpandCollapse } from "monday-ui-react-core";
import { ExternalPage, Video } from "monday-ui-react-core/icons";
import mondaySdk from "monday-sdk-js";
import FileUpload from './FileUpload';
import axios from 'axios';
import { downloadCreatedDocument } from './fileDownloader';
import { initiateDownloadFromFetchResponse, downloadExistingTemplate } from './fileDownloader';
import DOMPurify from 'dompurify';
import { convertMondayFilterToGraphQLFilter } from './MondayFilterHelper';
import { TemplateBuilder } from './TemplateBuilder';
import { PlaceholderListMonday } from './PlaceholderListMonday';
//import TowerMenuButton from './TowerMenuButton';

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class GenerateDocument extends Component {
    static displayName = GenerateDocument.name;

    constructor(props) {
        super(props);

        this.userLanguage = 'en';
        this.defaultColumnForGroupBy = { value: 'Group', label: 'Group' };

        this.state = {
            recipes: [],
            blueprints: [],
            templates: [],
            emailTemplates: [],
            selectedTemplate: null,
            dataSource: '',
            currentStep: 0,
            useLetterpaper: false,
            saveAsRecipe: false,
            documentType: 'Pdf',
            recipeName: '',
            fileName: '',
            loading: true,
            executing: false,
            isDownloadingDefaultTemplate: false,
            executingRecipeId: '',
            executingSaveRecipe: false,
            fileToColumn: false,
            fileToColumns: [],
            hasNoFileToColumn: false,
            selectedFileToColumn: undefined,
            sendEmailToMe: false,
            sendEmailToMeTemplate: null,
            sendEmailToColumn: false,
            sendEmailToColumnTemplate: null,
            sendEmailColumns: [],
            hasNoSendEmailColumn: false,
            selectedSendEmailColumn: undefined,
            sendEmailToCustomAddresses: false,
            sendEmailToCustomAddressesTemplate: null,
            customEmailAddresses: '',
            documentContent: undefined,
            documentError: undefined,
            documentCreatedMessage: '',
            showTipseen: false,            
            selectedItem: undefined,
            selectedAttachmentColumn: undefined,
            attachFilesFromColumn: false,
            useFilter: true,
            mondayFilterText: null,
            mapBlueprintFields: false,
            selectedBlueprint: undefined,
            templateCreating: false,
            steps: this.getDefaultSteps(),
            blueprintMappedColumns: {},
            blueprintTableSelectedColumns: [],
            showTemplateBuilder: false,
            showCreateAndUploadTemplate: false,
            defaultTemplateDownloaded: false,
            finetuneTemplate: false,
            selectedTemplateDefinition: null,
            blueprintTableGroupByColumn: this.defaultColumnForGroupBy,
        };

        //this.state.documentContent = {
        //    id: "4d945e10-3fac-4a86-8ea1-d59c6156798d",
        //    name: "Document name.pdf"
        //}
        //this.state.documentCreatedMessage = `Due to technical limitations the document cannot be added to the column Documents. Please setup an integration to achieve this!`;

        this.mondayContext = window.mondayContext;
        if (this.mondayContext)
            this.userLanguage = this.mondayContext.user.currentLanguage;
        this.towerAppInfos = window.towerAppInfos;

        this.tipseenDismissed = true;
        this.dismissTipseenIdentifier = "TipseenDismissed-" + this.mondayContext.user.id;
        this.localStorageStateIdentifier = "DocExport" + this.mondayContext.boardId + "_" + this.mondayContext.boardViewId;
        if (this.mondayContext.connected) {
            monday.storage.instance.getItem(this.dismissTipseenIdentifier).then(res => {                
                var value = res.data.value;                
                var showTipseen = true;
                if (value)
                    showTipseen = false;                
                this.tipseenDismissed = !showTipseen;
                // Set state after 2 seconds
                setTimeout(() => {
                    this.setState({ showTipseen: showTipseen });
                }, 1000);
            });
        } else {
            var showTipseen = true;
            this.tipseenDismissed = !showTipseen;            
            // Set state after 2 seconds
            setTimeout(() => {
                console.log("Not connected " + showTipseen);
                this.setState({ showTipseen: showTipseen });
            }, 1000);
        }

        this.texts = {
            pleaseDontCloseText: "Please don't close this tab while generating the document.",
            selectItem: 'Please select an item',
            sendEmailTemplate: 'Email template',
            attachmentColumn: '',
            sendEmailToColumn: '',
            fileToColumn: '',
            dataSourceBoardDescription: 'Generate a document for the entire board as a table view, including all items in one file.',
            dataSourceItemDescription: 'Generate a document for a single or several items triggered by an automation like button click, change of status or date.',
            dataSourceItemExplanation: '(e.g. contract, proposal, agreement, certificate, invoice, letter,…)',
            dataSourceBoardExplanation: '(e.g. project report, charts, timesheet, tasks, product/service list, lead pipeline, …)',
            recommendedDataSource: 'Item',
            dataSourceItemHeader: 'Item level / Automation',
            stepActionsTitle: 'What should happen with the document after creating it?',
            saveInApp: 'Save in DocExport view (Tab: Documents)',
            allDocumentsAreSavedInAppText: 'By default, all documents will be saved in the "Documents" tab in the DocExport view.',
            sendToMeCheckboxText: 'Send via email to user who generates the document',
            explanationSendEmailToMe: 'Send it to the user who generates the document via email.',
            documentCreatedSuccessHeader: 'Your document was generated successfully',
            downloadDocument: 'Download document',
            saveThisRecipe: 'Save this DocExport recipe for future use',
            saveThisRecipeExplanation: 'Save DocExport recipe for future use: You can save all settings as a DocExport recipe in order to reuse them again.',
            generateDocument: 'Generate Document',
            errorGeneratingDocument: 'Error generating your document',
            useTemplateBuilder: 'Use Template Builder',
            document: 'document',
            Document: 'Document',
            documents: 'documents',
            DocExportRecipe: 'DocExport recipe',
            specifyRecipeNameExplanation: 'Please specify a name for the recipe so that you can easily identify it later when creating the automation, e.g. "Create contract", "Send invoice", "Create and save certificate".',
            DocExport: 'DocExport',
            generatedDocumentsCanAlwaysBeFoundHere: "Generated documents can always be found here on the 'Documents' tab",
            selectBoardSvg: '/SelectBoard.svg',
            selectSingleItemSvg: '/SelectSingleItem.svg',
            applyFilterToolTip: "Use board filters to narrow down the items you want to be included in your document. Click on 'Filter' in the header bar of this board view to select and save your filter settings.",
        }

        if (this.props.isReportGenerator) {
            this.texts.dataSourceBoardDescription = 'Generate a report for the entire board, including all items in one file.';
            this.texts.dataSourceItemDescription = 'Generate a report for a single item representing a project.';
            this.texts.recommendedDataSource = 'Board';
            this.texts.dataSourceItemHeader = 'Item level';
            this.texts.dataSourceItemExplanation = '';
            this.texts.dataSourceBoardExplanation = '';
            this.texts.stepActionsTitle = 'What should happen with the report after creating it?';
            this.texts.saveInApp = 'Save in Report Generator view (Tab: Reports)';
            this.texts.allDocumentsAreSavedInAppText = 'By default, all reports will be saved in the "Reports" tab in the Report Generator view.';
            this.texts.sendToMeCheckboxText = 'Send via email to user who generates the report';
            this.texts.explanationSendEmailToMe = 'Send it to the user who generates the report via email.';
            this.texts.documentCreatedSuccessHeader = 'Your report was generated successfully';
            this.texts.downloadDocument = 'Download report';
            this.texts.saveThisRecipe = 'Save this Report Generator recipe for future use';
            this.texts.saveThisRecipeExplanation = 'Save Report Generator recipe for future use: You can save all settings as a Report Generator recipe in order to reuse them again.';
            this.texts.generateDocument = 'Generate Report';
            this.texts.errorGeneratingDocument = 'Error generating your report';
            this.texts.useTemplateBuilder = 'Use Report Builder';
            this.texts.document = 'report';
            this.texts.Document = 'Report';
            this.texts.documents = 'reports';
            this.texts.DocExportRecipe = 'Report Generator recipe';
            this.texts.specifyRecipeNameExplanation = 'Please specify a name for the recipe so that you can easily identify it later when creating the automation, e.g. "Project report", "Weekly report", "Items by users".';
            this.texts.DocExport = 'Report Generator';
            this.texts.generatedDocumentsCanAlwaysBeFoundHere = "Generated reports can always be found here on the 'Reports' tab";
            this.texts.selectBoardSvg = '/SelectBoard_ReportGenerator.svg';
            this.texts.selectSingleItemSvg = '/SelectSingleItem_ReportGenerator.svg';
            this.texts.applyFilterToolTip = "Use board filters to narrow down the items you want to be included in your report. Click on 'Filter' in the header bar of this board view to select and save your filter settings.";
        }

        //if (this.userLanguage === 'de') {
        //    this.texts = {
        //        pleaseSelect: 'Bitte w\u00E4hle ein Land, um die Feiertage zum Board hinzuzuf\u00FCgen:',
        //        addToBoard: 'In Board eintragen',
        //        selectCountry: 'Land',
        //        selectCounty: 'Bundesland',
        //        dateColumn: 'Datumsspalte',
        //        date: 'Datum',
        //        counties: 'Bundesl\u00E4nder',
        //        successMessage: 'Die Feiertage wurden erfolgreich angelegt',
        //        pleaseDontCloseText: 'Bitte schließe diesen Tab nicht solange das Dokument erzeugt wird!',
        //        pleaseCreateADateColumnText: 'Bitte lege zuerst eine Datumsspalte an im Board!'
        //        selectItem
        //    }
        //}

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.selectTemplateClick = this.selectTemplateClick.bind(this);
        this.handleMultiStepClick = this.handleMultiStepClick.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleUseLetterpaperCheckboxChange = this.handleUseLetterpaperCheckboxChange.bind(this);
        this.handleSaveRecipeCheckboxChange = this.handleSaveRecipeCheckboxChange.bind(this);
        this.handleRecipeNameChange = this.handleRecipeNameChange.bind(this);
        this.handleSendEmailCustomAddressesChange = this.handleSendEmailCustomAddressesChange.bind(this);
        this.handleFileNameChange = this.handleFileNameChange.bind(this);
        this.selectDataSourceClick = this.selectDataSourceClick.bind(this);
        this.generateButtonClick = this.generateButtonClick.bind(this);
        this.generateForItemButtonClick = this.generateForItemButtonClick.bind(this);
        this.createRecipeForIntegrationButtonClick = this.createRecipeForIntegrationButtonClick.bind(this);
        this.handleFileToColumnChange = this.handleFileToColumnChange.bind(this);
        this.handleSendEmailToColumnChange = this.handleSendEmailToColumnChange.bind(this);
        this.handleFileToColumnCheckboxChange = this.handleFileToColumnCheckboxChange.bind(this);
        this.handleSendEmailToMeCheckboxChange = this.handleSendEmailToMeCheckboxChange.bind(this);
        this.handleSendEmailToColumnCheckboxChange = this.handleSendEmailToColumnCheckboxChange.bind(this);
        this.handleSendEmailToCustomAddressesCheckboxChange = this.handleSendEmailToCustomAddressesCheckboxChange.bind(this);
        this.fileUploadComplete = this.fileUploadComplete.bind(this);
        this.changeDocumentType = this.changeDocumentType.bind(this);
        this.handleUseRecipeButtonClick = this.handleUseRecipeButtonClick.bind(this);
        this.handleUseBlueprintButtonClick = this.handleUseBlueprintButtonClick.bind(this);
        this.handleUseBoardPrintBlueprintButtonClick = this.handleUseBoardPrintBlueprintButtonClick.bind(this);
        this.handleUseTemplateButtonClick = this.handleUseTemplateButtonClick.bind(this);
        this.closeAssistant = this.closeAssistant.bind(this);
        this.downloadCreatedDocument = this.downloadCreatedDocument.bind(this);
        this.previewCreatedDocument = this.previewCreatedDocument.bind(this);
        this.downloadDefaultTemplate = this.downloadDefaultTemplate.bind(this);
        this.handleTemplateSelectRadio = this.handleTemplateSelectRadio.bind(this);
        this.dismissTipseen = this.dismissTipseen.bind(this);
        this.removeMondayStorageEntry = this.removeMondayStorageEntry.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.handleSelectedItemChange = this.handleSelectedItemChange.bind(this);
        this.handleCreateTemplateFromDefaultTemplateClick = this.handleCreateTemplateFromDefaultTemplateClick.bind(this);
        this.handleSendEmailToMeTemplateChange = this.handleSendEmailToMeTemplateChange.bind(this);
        this.handleSendEmailToColumnTemplateChange = this.handleSendEmailToColumnTemplateChange.bind(this);
        this.handleSendEmailToCustomAddressesTemplateChange = this.handleSendEmailToCustomAddressesTemplateChange.bind(this);
        this.handleAttachmentColumnChange = this.handleAttachmentColumnChange.bind(this);
        this.handleAttachFilesFromColumnCheckboxChange = this.handleAttachFilesFromColumnCheckboxChange.bind(this);
        this.fileUploadCompleteTemplateReplace = this.fileUploadCompleteTemplateReplace.bind(this);
        this.handleUseFilterCheckboxChange = this.handleUseFilterCheckboxChange.bind(this);
        this.handleMapBlueprintColumnChange = this.handleMapBlueprintColumnChange.bind(this);
        this.handleMapFieldsReadyButtonClick = this.handleMapFieldsReadyButtonClick.bind(this);
        this.handleTableColumnCheckboxChange = this.handleTableColumnCheckboxChange.bind(this);
        this.handleTemplateBuilderClick = this.handleTemplateBuilderClick.bind(this);
        this.handleTemplateBuilderClose = this.handleTemplateBuilderClose.bind(this);
        this.handleCreateAndUploadTemplateClick = this.handleCreateAndUploadTemplateClick.bind(this);
        this.handleSkipDownloadDefaultTemplateButtonClick = this.handleSkipDownloadDefaultTemplateButtonClick.bind(this);
        this.handleBlueprintTableGroupByColumnChange = this.handleBlueprintTableGroupByColumnChange.bind(this);
    }

    handleSkipDownloadDefaultTemplateButtonClick() {
        this.setState({ defaultTemplateDownloaded: true });
    }

    handleCreateAndUploadTemplateClick() {
        this.setState({ showCreateAndUploadTemplate: true });
    }

    handleTemplateBuilderClick() {
        this.setState({ showTemplateBuilder: true });
    }

    handleTableColumnCheckboxChange(table, column) {
        var id = table.name + '-' + column.id;
        var newCheckedColumns;
        if (this.state.blueprintTableSelectedColumns.includes(id))
            newCheckedColumns = this.state.blueprintTableSelectedColumns.filter(c => c !== id);
        else
            newCheckedColumns = [...this.state.blueprintTableSelectedColumns, id];
        this.setState({ blueprintTableSelectedColumns: newCheckedColumns });
    }

    handleUseFilterCheckboxChange() {
        this.setState({ useFilter: !this.state.useFilter });        
    }

    removeMondayStorageEntry() {
        //console.log('Removing');
        monday.storage.instance.removeItem(this.dismissTipseenIdentifier).then(res => {
            //console.log(res);
        });
    }

    getDefaultSteps() {
        return [
            //{
            //key: "DATA",
            //status: MultiStepIndicator.stepStatuses.ACTIVE,
            //titleText: "Step 1",
            //subtitleText: "What is it you want to do?"
            //},
            {
                key: "TEMPLATE",
                status: MultiStepIndicator.stepStatuses.ACTIVE,
                titleText: "Step 1",
                subtitleText: this.props.isReportGenerator ? "Create report" : "Select template"
            }, {
                key: "ACTIONS",
                status: MultiStepIndicator.stepStatuses.PENDING,
                titleText: "Step 2",
                subtitleText: "Select actions"
            }, {
                key: "SETTINGS",
                status: MultiStepIndicator.stepStatuses.PENDING,
                titleText: "Step 3",
                subtitleText: "More settings"
            }];
    }

    componentDidMount() {
        // Load state from local storage
        try {
            const savedState = localStorage.getItem(this.localStorageStateIdentifier);
            if (savedState) {
                //console.log('Using saved state ' + this.localStorageStateIdentifier + ": " + savedState);
                var savedStateParsed = JSON.parse(savedState);
                this.setState(savedStateParsed);
                if (savedStateParsed.dataSource) {
                    this.props.onAssistantModeChanges(true);
                }
                if (savedStateParsed.dataSource === "SingleItem") {
                    this.loadItemsForSelection();
                }
            }
            else
                this.setState({ ...this.state, loading: false });
        } catch (error) {
            console.error("Failed to load state from local storage:", error);
            // Handle the error or initialize state to a default value
        //    this.setState({
        //        // Your fallback initial state here
        //    });
        }
        this.populateRecipeData();
        this.populateTemplateData();
        this.loadColumnData();        
        if (this.towerAppInfos && this.towerAppInfos.hasEmailTemplates)
            this.populateEmailTemplateData();

        monday.listen('filter', res => {
            //console.log(res);
            var mondayFilterText = null;
            if (res.data.rules && res.data.rules.length > 0) {
                mondayFilterText = convertMondayFilterToGraphQLFilter(res.data.rules);
            }
            this.setState({ mondayFilterText: mondayFilterText });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loading)
            return;
        if (!this.state.dataSource)
            return;
        if (this.state.executing)
            return;
        if (this.state.executingSaveRecipe)
            return;
        if (this.state.isDownloadingDefaultTemplate)
            return;
        // Save state to local storage
        if (this.state !== prevState) {
            if (this.state.documentContent)
                localStorage.removeItem(this.localStorageStateIdentifier);
            else
                localStorage.setItem(this.localStorageStateIdentifier, JSON.stringify(this.state));
        }
    }

    async loadItemsForSelection() {
        if (!this.mondayContext.connected) {            
            //var items = [{ id: '1375079942', name: 'Towerapps' }];
            var items = [{ id: '5214736008', name: 'julitec' }]; 
            this.itemsForSelection = items.map(c => ({ value: c.id, label: c.name }));
            return;
        }

        var itemsLimit = 200;
        var itemsQueryPart = `
      items {
        id
        name
        group {
            id
        }
      }
`;

        var boardData = await monday.api(`
{
  complexity {
    query
    after
  }
  boards(ids: [${this.mondayContext.boardId}]) {
    id
    name
    item_terminology
    items_page(
      limit: ${itemsLimit},
    ) {
      cursor
      ${itemsQueryPart}
    }
  }
}
`, { apiVersion: '2023-10' });

        var board = boardData.data.boards.find(b => b.id === this.mondayContext.boardId.toString());
        if (!board) {
            alert("Board not found");
            throw new Error("Board not found");
        }
        //console.log(board);
        if (board.items_page && board.items_page.items) {
            this.itemsForSelection = board.items_page.items.map(c => ({ value: c.id, label: c.name }));
            //console.log(this.itemsForSelection);
        }
    }

    getItemsQueryPart(withAssets) {
        var assetsPart = withAssets ? `assets { id public_url name }` : '';
        return `
      items {
        id
        name
        group {
            id
        }
        ${assetsPart}
        column_values {
          id
          type
          text
          value
          ... on MirrorValue {
            display_value
          }
          ... on BoardRelationValue {
            display_value
          }
        }
      }
`;
    }

    async loadBoardData() {
        if (!this.mondayContext.connected) {
            // TODO: What now?
            return;
        }

        // DONE: Load only item, when SingleItem mode is selected

        var itemsLimit = 100;
        var itemsQueryPart = this.getItemsQueryPart(true);

        var itemFilterText = "";
        if (this.state.dataSource === "SingleItem" && this.state.selectedItem) {
            itemFilterText = `query_params: {ids: [${this.state.selectedItem.value}]}`;
            console.log(itemFilterText);
        }
        if (this.state.useFilter && this.state.mondayFilterText)
            itemFilterText = `query_params: {rules: ${this.state.mondayFilterText}}`;

        // Complexity with limit:500 1529580 (without assets: 24580)
        // Complexity with limit:100  309986 (without assets:  8986)
        var boardData = await monday.api(`
{
  complexity {
    query
    after
  }
  boards(ids: [${this.mondayContext.boardId}]) {
    id
    name
    item_terminology
    type
    description
    columns {
      title
      id
      type
      width
      settings_str
    }
    groups {
      id
      color
      title
    }
    owners {
      id
      name
      email
      phone
      photo_original
      photo_small
    }
    subscribers {
      id
      name
      email
      phone
      photo_original
      photo_small
    }
    items_page (
      limit: ${itemsLimit},
      ${itemFilterText}
    ) {
      cursor
      ${itemsQueryPart}
    }
  }
  me {
    id
    name
    email
    phone
    photo_original
    photo_small
    utc_hours_diff
    account {
      id
      name
      slug
    }
  }
}
`, { apiVersion: '2023-10' });

        var board = boardData.data.boards.find(b => b.id === this.mondayContext.boardId.toString());
        if (!board) {
            alert("Board not found");
            throw new Error("Board not found");
        }
        if (board.items_page && board.items_page.items && board.items_page.cursor) {
            var itemsData = board.items_page.items;
            var queryCursor = board.items_page.cursor;
            while (queryCursor) {
                //console.log("Query cursor: " + queryCursor);
                // TODO: Check complexity limit first -> Slow down?
                // Complexity with limit:100 12206 (without assets: 166)

                if (itemsData.length >= 500)
                    itemsQueryPart = this.getItemsQueryPart(false);

                var pageData = await monday.api(`
query {
  complexity {
    query
    after
  }
  next_items_page (limit: ${itemsLimit}, cursor: "${queryCursor}") {
    cursor
    ${itemsQueryPart}
  }
}
`, { apiVersion: '2023-10' });
                //console.log(pageData);
                queryCursor = undefined;
                if (pageData && pageData.data && pageData.data.next_items_page && (pageData.data.next_items_page.items.length > 0)) {
                    itemsData = itemsData.concat(pageData.data.next_items_page.items);
                    queryCursor = pageData.data.next_items_page.cursor;
                }
            }
            board.items_page.items = itemsData;
        }

        return boardData;
    }

    loadColumnData() {
        if (!this.mondayContext.connected) {
            this.mondayColumnData = {
                boards: [{
                    id: '1',
                    name: 'Board name',
                    columns:
                        [{ id: 'files88', title: 'Dateispalte', type: 'file' },
                        { id: 'date', title: 'Datumsspalte', type: 'date', width: 150 },
                        { id: 'subtasks', title: 'Subtasks', type: 'subtasks' },
                        { id: 'numbers', title: 'Number', type: 'numbers', width: 180 },
                        { id: 'text', title: 'Text', type: 'text', width: 200 },
                        { id: 'location', title: 'Location', type: 'location' },
                        { id: 'timelne', title: 'Timeline', type: 'timeline' }]
                }]
            };
            this.fileToColumnsRaw = [{ id: 'files88', title: 'Dateispalte', type: 'file' }];
            var fileToColumnsDemo = this.fileToColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                fileToColumns: fileToColumnsDemo
            });
            this.setState({
                selectedFileToColumn: fileToColumnsDemo[0]
            });

            this.sendEmailColumnsRaw = [{ id: 'e_mail', title: 'E-Mail', type: 'email' },
                { id: 'people', title: 'People', type: 'people' }];
            var sendEmailColumnsDemo = this.sendEmailColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                sendEmailColumns: sendEmailColumnsDemo
            });

            return;
        }
        monday.api(this.GetColumnQuery(this.mondayContext.boardId), { apiVersion: '2023-10' }).then(columnData => {
            //console.log(columnData);
            this.mondayColumnData = columnData.data;
            this.fileToColumnsRaw = columnData.data.boards[0].columns.filter(c => c.type === 'file');
            var fileToColumns = this.fileToColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                fileToColumns: fileToColumns
            });
            // Show error when 0 file columns exist
            if (fileToColumns.length === 0) {
                this.setState({
                    hasNoFileToColumn: true
                });
            }
            // If only 1 file column exists -> select it
            if (fileToColumns.length === 1) {
                this.setState({
                    selectedFileToColumn: fileToColumns[0]
                });
            }
            
            this.sendEmailColumnsRaw = columnData.data.boards[0].columns.filter(c => c.type === 'email' // Nach Rücksprache mit CH am 25.11.2023 raus: || c.type === 'text');
                || c.type === 'people');
            var sendEmailColumns = this.sendEmailColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                sendEmailColumns: sendEmailColumns
            });
            // Show error when 0 file columns exist
            if (sendEmailColumns.length === 0) {
                this.setState({
                    hasNoSendEmailColumn: true
                });
            }
            // If only 1 file column exists -> select it
            if (sendEmailColumns.length === 1) {
                this.setState({
                    selectedSendEmailColumn: sendEmailColumns[0]
                });
            }

            this.loadMirrorColumnData();
            if (this.state.selectedBlueprint?.needsSubitems)
                this.loadSubItemsColumnData();
        });
    }

    GetColumnQuery(boardId) {
        return `
query {
  complexity {
    query
    after
  }
  boards (ids: ${boardId}) { 
    id      
    name
    item_terminology
    columns {
        id
        title
        type
        width
        settings_str
    }
  }
}`;
    }

    getColumnSettings(column) {
        if (column.settings)
            return column.settings;
        if (!column.settings_str)
            return undefined;
        var settings;
        try {
            // Parse the settings_str JSON string
            settings = JSON.parse(column.settings_str);
            column.settings = settings;
        } catch (error) {
            console.error("Error parsing settings string:", error);
            return;
        }
        return settings;
    }

    loadMirrorColumnData() {
        if (!this.mondayColumnData)
            return;

        var mirrorColumns = this.mondayColumnData.boards[0].columns.filter(c => c.type === 'mirror');

        // Initialize arrays to hold all the IDs and their corresponding values
        var allBoardIds = [];
        var allColumnIds = []; // This will store arrays of values associated with each ID

        // Iterate over each mirror column
        mirrorColumns.forEach(column => {
            // Parse the settings_str to an object
            const settingsObj = this.getColumnSettings(column);

            // Check if displayed_linked_columns exists
            if (settingsObj.displayed_linked_columns) {
                // Access the displayed_linked_columns object
                const displayedLinkedColumns = settingsObj.displayed_linked_columns;

                // Extract the keys (IDs) and values from displayed_linked_columns
                Object.entries(displayedLinkedColumns).forEach(([id, value]) => {
                    allBoardIds.push(id);
                    // Assuming you want to flatten the values into a single list
                    allColumnIds = allColumnIds.concat(value);
                });
            }
        });

        if (allBoardIds.length === 0)
            return;
        if (allColumnIds.length === 0)
            return;
        monday.api(this.getMirrorColumnsQuery(allBoardIds, allColumnIds), { apiVersion: '2023-10' }).then(columnData => {
            //console.log(columnData);
            mirrorColumns.forEach(column => {
                const settingsObj = this.getColumnSettings(column);

                // Check if displayed_linked_columns exists
                if (settingsObj.displayed_linked_columns) {
                    // Access the displayed_linked_columns object
                    const displayedLinkedColumns = settingsObj.displayed_linked_columns;
                    if (displayedLinkedColumns.length === 0)
                        return;
                    // Get the first entry (ID and value array) from displayed_linked_columns
                    const [firstBoardId, firstValueArray] = Object.entries(displayedLinkedColumns)[0];
                    if (firstValueArray.length === 0)
                        return;
                    const firstColumnId = firstValueArray[0];

                    var mirroredBoard = columnData.data.boards.find(b => b.id === firstBoardId);
                    if (!mirroredBoard)
                        return;
                    var mirroredColumn = mirroredBoard.columns.find(c => c.id === firstColumnId);
                    if (!mirroredColumn)
                        return;
                    //console.log('Mirrored column found for ' + column.title + ': ' + mirroredBoard.name + ' - ' + mirroredColumn.title);
                    column.mirrorType = mirroredColumn.type;
                    column.mirrorSettings = mirroredColumn.settings_str;
                    //console.log(column);
                }
            });
        });
    }

    getMirrorColumnsQuery(boardIds, columnIds) {
        // Convert boardIds array to a string with items separated by commas and wrapped in brackets
        const boardIdsStr = `[${boardIds.join(", ")}]`;

        // Convert columnIds array to a string with items quoted, separated by commas, and wrapped in brackets
        const columnIdsStr = `["${columnIds.join('", "')}"]`;

        return `
query {
  complexity {
    query
    after
  }
  boards (ids: ${boardIdsStr}) {
    id
    columns (ids: ${columnIdsStr}) {
      id
      type
      title
      settings_str
      width
    }
  }
}`;
    }

    async populateTemplateData() {
        const response = await fetch('template/list?TemplateType=docx&BoardId=' + this.mondayContext.boardId + '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        this.setState({ templates: data });
    }

    async populateEmailTemplateData() {
        const response = await fetch('template/list?TemplateType=html&BoardId=' + this.mondayContext.boardId + '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        var emailTemplates = data.map(c => ({ value: c.id, label: c.name }));
        if (emailTemplates.length > 0)
            emailTemplates.unshift({ value: '', label: 'Default Template' });
        this.setState({ emailTemplates: emailTemplates });
    }

    async populateRecipeData() {
        const response = await fetch('recipe/listWithBlueprints?Destination=Board&BoardId=' + this.mondayContext.boardId + '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        this.setState({ recipes: data.recipes, blueprints: data.blueprints });
    }

    fileUploadComplete(success, message, template) {
        if (!success)
            alert(message);
        if (success)
            if (template) {
                this.setState({ templates: [...this.state.templates, template] });
                this.setState({ selectedTemplate: template, defaultTemplateDownloaded: false, finetuneTemplate: false });
                this.setMultiStepActiveStep(this.state.currentStep + 1);
            }
    }

    fileUploadCompleteTemplateReplace(success, message, template) {
        if (!success)
            alert(message);
        if (success)
            if (template) {
                this.generateButtonClick();
            }
    }

    generateUniqueID() {
        return 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now();
    }

    getDataForPOST() {        
        var sendEmailColumnId = '';
        if (this.state.sendEmailToColumn)
            sendEmailColumnId = this.state.selectedSendEmailColumn?.value;
        var sendEmailCustomAddresses = '';
        if (this.state.sendEmailToCustomAddresses)
            sendEmailCustomAddresses = this.state.customEmailAddresses;
        var attachmentColumnId = '';
        if (this.state.attachFilesFromColumn)
            attachmentColumnId = this.state.selectedAttachmentColumn?.value;
        const data = {
            requestId: this.generateUniqueID(),
            accountId: this.mondayContext.account?.id,
            boardId: this.mondayContext.boardId,
            userId: this.mondayContext.user.id,
            workspaceId: this.mondayContext.workspaceId,
            userCountryCode: this.mondayContext.user.countryCode,
            userLanguage: this.mondayContext.user.currentLanguage,
            userTimeZoneOffset: this.mondayContext.user.timeZoneOffset,
            userTimeFormat: this.mondayContext.user.timeFormat,
            templateId: DOMPurify.sanitize(this.state.selectedTemplate?.id),
            sessionToken: window.mondaySessionToken,
            mode: DOMPurify.sanitize(this.state.dataSource),
            documentType: DOMPurify.sanitize(this.state.documentType),
            fileDocument: true,
            saveAsRecipe: this.state.saveAsRecipe,
            recipeName: DOMPurify.sanitize(this.state.recipeName),
            fileName: DOMPurify.sanitize(this.state.fileName),
            useLetterpaper: this.state.useLetterpaper,
            fileToColumn: this.state.fileToColumn,
            fileToColumnId: DOMPurify.sanitize(this.state.selectedFileToColumn?.value),
            fileToColumnName: DOMPurify.sanitize(this.state.selectedFileToColumn?.label),
            sendEmailToMe: this.state.sendEmailToMe,
            sendEmailToColumn: this.state.sendEmailToColumn,
            sendEmailToColumnId: DOMPurify.sanitize(sendEmailColumnId),
            sendEmailToColumnName: DOMPurify.sanitize(this.state.selectedSendEmailColumn?.label),
            sendEmailToCustomAddresses: this.state.sendEmailToCustomAddresses,
            emailCustomAddresses: DOMPurify.sanitize(sendEmailCustomAddresses),
            sendEmailToMeTemplateId: DOMPurify.sanitize(this.state.sendEmailToMeTemplate?.value),
            sendEmailToColumnTemplateId: DOMPurify.sanitize(this.state.sendEmailToColumnTemplate?.value),
            sendEmailToCustomAddressesTemplateId: DOMPurify.sanitize(this.state.sendEmailToCustomAddressesTemplate?.value),
            generateThumbnail: true,
            emailAttachmentColumnId: DOMPurify.sanitize(attachmentColumnId)
        };
        if (this.state.dataSource === "SingleItem")
            if (this.state.selectedItem)
                data.itemId = this.state.selectedItem.value;
        if (this.state.documentContent)
            data.predecessorVersionId = this.state.documentContent.id;
        if (this.state.useFilter && this.state.mondayFilterText) {
            data.itemFilter = this.state.mondayFilterText;
        }
        return data;
    }

    async createRecipeForIntegrationButtonClick() {
        this.setState({ executingSaveRecipe: true });
        const data = this.getDataForPOST();
        try {
            const response = await axios.post('recipe/createNew', data);
            console.log('Success:', response.data);
            this.setState({ executingSaveRecipe: false });
        } catch (error) {
            console.error('Error:', error);
            this.setState({ executingSaveRecipe: false });
        }

        this.setMultiStepActiveStep(this.state.currentStep + 1);
        monday.execute("valueCreatedForUser");
    }

    handleButtonClick() {
        this.setMultiStepActiveStep(this.state.currentStep + 1);
    }

    selectTemplateClick(event, template) {
        this.setState({ selectedTemplate: template });
        this.setMultiStepActiveStep(this.state.currentStep + 1);
    }

    selectDataSourceClick(event, dataSource) {
        axios.post('template/dataSourceSelected', {
            dataSource: dataSource,
            boardId: this.mondayContext.boardId,
            sessionToken: window.mondaySessionToken
        })
            .then(response => console.log('Success:', response.data))
            .catch(error => console.error('Error:', error));
        this.internalSelectDataSource(dataSource, 0);
    }

    internalSelectDataSource(dataSource, stepNumber) {        
        if (dataSource === "SingleItem") {
            let newSteps = JSON.parse(JSON.stringify(this.state.steps));
            // Check if a step with key 'INTEGRATION' already exists
            const integrationStepExists = newSteps.some(step => step.key === "INTEGRATION");
            if (!integrationStepExists) {
                newSteps.push({
                    key: "INTEGRATION",
                    status: MultiStepIndicator.stepStatuses.PENDING,
                    titleText: "Step 4",
                    subtitleText: "Instructions"
                });
            }
            this.setMultiStepStatusValues(newSteps, stepNumber);
            this.setState({ dataSource: dataSource, currentStep: stepNumber, steps: newSteps });
            this.loadSubItemsColumnData();
            this.loadItemsForSelection();
        }
        else {
            var newSteps = this.getDefaultSteps();
            this.setMultiStepStatusValues(newSteps, stepNumber);
            this.setState({ dataSource: dataSource, currentStep: stepNumber, steps: newSteps, selectedItem: undefined });
            //this.setMultiStepActiveStep(stepNumber);
        }
        this.props.onAssistantModeChanges(true);
    }

    boardHasSubItemsColumn() {
        if (!this.mondayColumnData || !this.mondayColumnData.boards || !this.mondayColumnData.boards[0].columns)
            return false;

        var subItemsColumns = this.mondayColumnData.boards[0].columns.filter(c => c.type === 'subtasks');
        return subItemsColumns.length > 0;
    }

    // TODO: Remove?
    loadSubItemsColumnData() {
        if (this.mondaySubItemsColumnData)  // Already loaded
            return;
        if (!this.mondayContext.connected) {
            this.mondaySubItemsColumnData = {
                boards: [{
                    id: '1',
                    name: 'SubItems Board',
                    columns: [{ id: '1', title: 'Zahl', type: 'numbers' }]
                }]
            };
        }

        if (!this.mondayColumnData || !this.mondayColumnData.boards || !this.mondayColumnData.boards[0].columns)
            return;

        var subItemsColumns = this.mondayColumnData.boards[0].columns.filter(c => c.type === 'subtasks');

        if (subItemsColumns.length === 0)
            return;

        var subItemsColumn = subItemsColumns[0];

        var settings;
        try {
            // Parse the settings_str JSON string
            settings = JSON.parse(subItemsColumn.settings_str);
        } catch (error) {
            console.error("Error parsing settings string:", error);
            return;
        }

        // Access the boardIds property
        var boardIds = settings.boardIds;
        if (!boardIds || boardIds.length === 0)
            return;

        var boardId = boardIds[0];

        monday.api(this.GetColumnQuery(boardId), { apiVersion: '2023-10' })
            .then(columnData => {
                //console.log(columnData);
                this.mondaySubItemsColumnData = columnData.data;
                this.forceUpdate(); // TODO: Remove -> Use state instead
            })
            .catch(error => {
                console.error("API call failed:", error);
            });
    }

    async generateForItemButtonClick() {
        //var text = '';
        //if (this.state.fileToColumn && this.state.selectedFileToColumn) {
        //    text = `Due to technical limitations the document cannot be added to the column ${this.state.selectedFileToColumn.label}. Please setup an integration to achieve this!`;
        //}
        //this.setState({ documentCreatedMessage: text });
        await this.generateButtonClick();
    }

    async generateButtonClick() {
        const data = this.getDataForPOST();
        await this.generateDocument(data);

        if (this.state.saveAsRecipe)
            if (this.state.dataSource !== "SingleItem")
                this.populateRecipeData();
    }

    async generateDocument(data) {
        this.setState({ executing: true, executingRecipeId: data.recipeId, });
        monday.execute("valueCreatedForUser");

        monday.execute("notice", {
            message: this.texts.pleaseDontCloseText,
            type: "info", // or "error" (red), or "info" (blue)
            timeout: 3000,
        });

        var boardData = await this.loadBoardData();
        if (boardData)
            data.boardData = boardData.data;
        
        this.startWaitForDataRequest(data.requestId);

        try {
            const response = await axios.post('document/generateDocument', data);
            //console.log(response.data);
            if (response.data.result) {
                this.setState({ executing: false, documentContent: response.data.document, documentError: undefined });
                localStorage.removeItem(this.localStorageStateIdentifier);
                this.props.onAssistantModeChanges(false, response.data.documentLimitReached);
                if (this.state.dataSource === "SingleItem" && this.state.fileToColumn && this.state.selectedFileToColumn) {
                    this.uploadFileToMonday(response.data);
                }
            } else {
                // TODO
                var errorMessage = 'Error generating document: ' + response.data.error;
                console.log(errorMessage);
                if (response.data.documentLimitReached) {
                    alert(errorMessage);
                    this.setState({ executing: false });
                }
                else {
                    this.setState({ executing: false, documentError: response.data, documentContent: undefined });
                }
            }
        } catch (error) {
            // TODO: Send error to server
            console.error('Error:', error);
            alert(error);
            this.setState({ executing: false });
        }
    }

    async uploadFileToMonday(serverResponse) {
        console.log('Uploading file to monday');
        //console.log(serverResponse);

        // GraphQL mutation for uploading the file
        const fileMutation = `
    mutation add_file($itemId: ID!, $columnId: String!, $file: File!) {
      add_file_to_column(item_id: $itemId, column_id: $columnId, file: $file) {
        id
      }
    }
  `;

        try {
            // Fetch the file from the server
            var downloadUrl = '/document/Download?Id=' + serverResponse.document.id + '&SessionToken=' + window.mondaySessionToken;
            const response = await fetch(downloadUrl);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            // Convert the response to a Blob
            const blob = await response.blob();

            // Create a File object from the Blob
            // Assuming you know the file type and name, replace 'your-file-name' and 'file/type' accordingly
            //console.log(serverResponse.contentType);
            const file = new File([blob], serverResponse.document.name, { type: serverResponse.contentType });

            // Variables for the mutation
            var columnId = this.state.selectedFileToColumn.value;
            const variables = { itemId: serverResponse.document.itemId, columnId: columnId, file };

            // Execute the mutation
            monday.api(fileMutation, { variables, apiVersion: '2023-10' })
                .then((res) => {
                    if (res) {
                        //console.log(res);                        
                        var assetId = res.data.add_file_to_column.id;                        
                        if (assetId) {
                            var assetData = {
                                assetId: assetId,
                                columnId: columnId,
                                documentId: serverResponse.document.id,
                                sessionToken: window.mondaySessionToken
                            };
                            axios.post('document/assignAssetId', assetData)
                                .then(assetResponse => {
                                    console.log(assetResponse);
                                })
                                .catch(assetError => {
                                    console.error('Error with AssignAssetId:', assetError);
                                });
                        }
                    }
                })
                .catch((err) => console.log(err));
        } catch (err) {
            console.log(err);
        }    
    }

    startWaitForDataRequest(requestId, queryResult = undefined) {
        var waitForDataRequestData = {
            boardId: this.mondayContext.boardId,
            sessionToken: window.mondaySessionToken,
            requestId: requestId,
            queryResult: JSON.stringify(queryResult)
        };
        axios.post('document/WaitForDataRequest', waitForDataRequestData)
            .then(response => {
                console.log(response);
                if (response.data.action === 'Query') {
                    monday.api(response.data.query, { apiVersion: '2023-10' })
                        .then(queryResult => {
                            console.log(queryResult);
                            this.startWaitForDataRequest(requestId, queryResult.data);
                        }).catch(error => {
                            // This doesn't help much, because monday.api() only gives us back main-8c40824b532720c6a028.js:1 Error: Graphql validation errors
                            // See https://github.com/mondaycom/welcome-apps/issues/12
                            // Handle the error here
                            console.error('API call failed:', error);
                            //console.log(error);
                            //console.log(error.toString());
                            // Optionally, you can update your component's state to reflect the error
                            // this.setState({ error: error.message });
                            var errorInDataRequestData = {
                                boardId: this.mondayContext.boardId,
                                sessionToken: window.mondaySessionToken,
                                requestId: requestId,
                                errorMessage: error.message
                            };
                            axios.post('document/ErrorInDataRequest', errorInDataRequestData)
                                .then(response => {
                                    console.log('Error reported to server:', response.data);
                                })
                                .catch(serverError => {
                                    // Handle any errors in reporting the error to the server
                                    console.error('Failed to report error to server:', serverError);
                                });
                        });
                }
            })
            .catch(error => {
                console.error('Error with WaitForDataRequest:', error);
            });
    }

    handleMultiStepClick(stepNumber) {
        console.log(stepNumber);
        if (stepNumber > this.state.currentStep)
            return;
        this.setMultiStepActiveStep(stepNumber - 1);
    }

    handleCheckboxChange(event) {
        console.log(event);
    }

    handleUseLetterpaperCheckboxChange(event) {
        console.log(this.state);
        console.log(this.state.useLetterpaper);
        this.setState({ useLetterpaper: !this.state.useLetterpaper });
    }

    handleSaveRecipeCheckboxChange(event) {
        this.setState({ saveAsRecipe: !this.state.saveAsRecipe });
    }

    setMultiStepActiveStep(stepNumber) {
        // Deep copy the steps
        let newSteps = JSON.parse(JSON.stringify(this.state.steps));

        this.setMultiStepStatusValues(newSteps, stepNumber);

        // Update the state
        this.setState({ currentStep: stepNumber, steps: newSteps });
    }

    setMultiStepStatusValues(newSteps, stepNumber) {
        // Iterate over the steps and update the status
        newSteps.forEach((step, index) => {
            if (index < stepNumber) {
                step.status = MultiStepIndicator.stepStatuses.FULFILLED;
            } else if (index === stepNumber) {
                step.status = MultiStepIndicator.stepStatuses.ACTIVE;
            } else {
                step.status = MultiStepIndicator.stepStatuses.PENDING;
            }
        });
    }

    handleRecipeNameChange(text) {
        this.setState({ recipeName: text });
    }

    handleSendEmailCustomAddressesChange(text) {
        this.setState({ customEmailAddresses: text });
    }

    handleFileNameChange(text) {
        this.setState({ fileName: text });
    }

    render() {
        //return (
        //    <TowerMenuButton />
        //);

        if (this.mondayContext.user.isViewOnly) {
            return (
                <p>I am sorry, but you don't have write rights in monday. This app requires write rights.</p>
            );
        }

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderContent();

        return contents;
    }

    renderMapBlueprintFields() {        
        return (<div>
            <h3>{this.state.selectedBlueprint.name}</h3>
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-two-column">
                    <p>{this.state.selectedBlueprint.description}</p>
                    {this.state.selectedBlueprint.groups.length > 0 && <p>Please map columns:</p>}
                    {this.state.selectedBlueprint.groups.map(g =>
                        <div key={g.name}>
                            <p><b>{g.name}</b></p>
                            {g.fields.map(f =>
                                <Flex key={f.name}>
                                    <div className="app-spirit-mapfieldname">{f.displayName}{f.isMandatory && <span>*</span>}</div>
                                    <Dropdown
                                        className="app-spirit-dropdown"
                                        size={Dropdown.sizes.SMALL}
                                        onBlur={function noRefCheck() { }}
                                        onChange={(e) => this.handleMapBlueprintColumnChange(e, f, g)}
                                        onClear={function noRefCheck() { }}
                                        onFocus={function noRefCheck() { }}
                                        onInputChange={function noRefCheck() { }}
                                        onMenuClose={function noRefCheck() { }}
                                        onMenuOpen={function noRefCheck() { }}
                                        onOptionRemove={function noRefCheck() { }}
                                        onOptionSelect={function noRefCheck() { }}
                                        openMenuOnFocus={function noRefCheck() { }}
                                        value={this.getMappedColumnForBlueprintField(f, g)}
                                        options={this.getColumnsForMapping(f, g)}
                                        clearable={true}
                                        placeholder="Select a column"
                                    />
                                </Flex>
                            )}
                        </div>
                    )}
                    {this.getBlueprintTablesForColumnSelection().map(t =>
                        <div key={t.name}>
                            {this.renderBlueprintTableGroupBy(t)}
                            <p>Specify which columns you want to include in the table <b>{t.displayName}</b>:<br />
                               For optimal results, please choose a maximum of 10 columns.</p>
                            {this.getColumnsForTable(t).map(c =>
                                <Checkbox key={c.id} onChange={(e) => this.handleTableColumnCheckboxChange(t, c)} checked={this.isBlueprintTableColumnChecked(t, c)}
                                    label={c.item_terminology ?? c.title}
                                />
                            )}
                        </div>
                    )}
                    <br />
                    <Flex>
                        <Button disabled={this.state.hasMandatoryFieldsNotAssigned} loading={this.state.templateCreating} onClick={this.handleMapFieldsReadyButtonClick}>
                            Next
                        </Button>
                        <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                            Cancel
                        </Button>
                    </Flex>
                </div>
                <div className="app-spirit-two-column">
                    <img className="app-spirit-img-blueprint" src={"/Blueprints/" + this.state.selectedBlueprint.previewUrl} alt="Preview template" />
                    <p>You can customize the template at the very end by clicking "Download template" after the initial document generation.</p>
                    {this.state.selectedBlueprint.explainerVideoLink && <Link icon={Video} href={this.state.selectedBlueprint.explainerVideoLink} text="Explainer Video" />}
                </div>
            </Flex>
        </div>);
    }

    handleBlueprintTableGroupByColumnChange(selectedOption) {
        this.setState({ blueprintTableGroupByColumn: selectedOption });
    }

    renderBlueprintTableGroupBy(table) {
        if (!table.groupByDataNeeded)
            return undefined;
        var columns = this.getColumnsForTable(table);

        let typesNeeded = [];

        // Check if dataNeeded starts with "Type:" and extract types if it does
        if (table.groupByDataNeeded.startsWith("Type:")) {
            typesNeeded = table.groupByDataNeeded.split(':')[1].split(',');
        }            

        var options = columns.filter(c => typesNeeded.length === 0 || typesNeeded.includes(c.type)).map(column => {
            return { value: column.id, label: column.title, type: column.type };
        });
        options.unshift(this.defaultColumnForGroupBy);
        //options.unshift({ value: null, label: 'None' });

        var shouldRenderApplyFilterCheckbox = this.state.mondayFilterText;

        return (
            <Flex>
                <div className="app-spirit-label">Group by</div>
                <Dropdown
                    className="app-spirit-dropdown"
                    size={Dropdown.sizes.SMALL}
                    onBlur={function noRefCheck() { }}
                    onChange={this.handleBlueprintTableGroupByColumnChange}
                    onClear={function noRefCheck() { }}
                    onFocus={function noRefCheck() { }}
                    onInputChange={function noRefCheck() { }}
                    onMenuClose={function noRefCheck() { }}
                    onMenuOpen={function noRefCheck() { }}
                    onOptionRemove={function noRefCheck() { }}
                    onOptionSelect={function noRefCheck() { }}
                    openMenuOnFocus={function noRefCheck() { }}
                    value={this.state.blueprintTableGroupByColumn}
                    options={options}
                    clearable={false}
                    placeholder="Select a column"
                />
                <Tooltip content={this.texts.applyFilterToolTip}>
                    <Checkbox
                        onChange={this.handleUseFilterCheckboxChange}
                        disabled={!shouldRenderApplyFilterCheckbox}
                        checked={shouldRenderApplyFilterCheckbox && this.state.useFilter}
                        label="Apply board filter" />
                </Tooltip>
            </Flex>
        );
    }

    getBlueprintTablesForColumnSelection() {
        if (!this.state.selectedBlueprint)
            return [];
        if (!this.state.selectedBlueprint.tables)
            return [];
        return this.state.selectedBlueprint.tables.filter(t => {
            if (t.dataSource === "Subitems")
                return this.props.subItemsColumns;
            return true;
        });
    }

    updateHasMandatoryFieldsNotAssigned() {        
        if (!this.state.selectedBlueprint)
            return true;        
        var hasMandatoryFields = this.state.selectedBlueprint.groups.some(g => g.fields.some(f => f.isMandatory && !this.getMappedColumnForBlueprintField(f, g)));
        this.setState({ hasMandatoryFieldsNotAssigned: hasMandatoryFields });
    }

    getColumnsForTable(table) {
        var columns = this.props.columns;
        if (table.dataSource === "Subitems")
            columns = this.props.subItemsColumns;
        if (!columns)
            return [];
        let result = columns.filter(c => c.type !== 'subtasks' && c.type !== 'button');
        if (this.props.isReportGenerator)
            result = result.filter(c => c.type !== 'file');
        return result;
    }

    getColumnsForMappingForAGroup(group) {
        var columns = this.props.columns;
        if (group.ident === "Subitems")
            columns = this.props.subItemsColumns;
        return columns;
    }

    getColumnsForMapping(field, group) {
        if (field.lookupValues) {            
            return field.lookupValues;
        }
        //if (!this.mondayColumnData)
        //    return undefined;
        //var columns = this.mondayColumnData.boards[0].columns;
        //if (group.ident === "Subitems") {
        //    if (!this.mondaySubItemsColumnData)
        //        return undefined;
        //    columns = this.mondaySubItemsColumnData.boards[0].columns;
        //}
        var columns = this.getColumnsForMappingForAGroup(group);
        if (!columns)
            return [];

        // Initialize an array to hold the required types
        let typesNeeded = [];

        // Check if dataNeeded starts with "Type:" and extract types if it does
        if (field.dataNeeded.startsWith("Type:")) {
            typesNeeded = field.dataNeeded.split(':')[1].split(',');
        }

        return columns
            // Filter columns by the specified types, if any
            .filter(c => typesNeeded.length === 0 || typesNeeded.includes(c.type) || (typesNeeded.includes(c.mirrorType) && c.mirrorType !== 'location'))  // Mirror location not yet supported
            // Map the filtered columns to the desired format
            .map(c => ({ value: c.id, label: c.title, type: c.type, mirrorType: c.mirrorType }));
    }

    async handleMapFieldsReadyButtonClick() {
        //console.log(this.state.selectedBlueprint);
        this.setState({ templateCreating: true });

        try {
            const data = {
                accountId: this.mondayContext.account?.id,
                boardId: this.mondayContext.boardId,
                sessionToken: window.mondaySessionToken,
                blueprintFilename: DOMPurify.sanitize(this.state.selectedBlueprint.filename),
                itemTerminology: DOMPurify.sanitize(this.mondayColumnData.boards[0].item_terminology),
                FieldMappings: this.state.selectedBlueprint.groups.map(g => ({                    
                    name: g.name,
                    ident: g.ident,
                    fields: g.fields.map(f => {
                        var mappedColumn = this.getMappedColumnForBlueprintField(f, g);
                        return {
                            Name: f.name,
                            MappedColumnId: DOMPurify.sanitize(mappedColumn?.value),
                            MappedColumnName: DOMPurify.sanitize(mappedColumn?.label),
                            MappedColumnType: DOMPurify.sanitize(mappedColumn?.mirrorType ?? mappedColumn?.type),
                        };
                    })
                })),
            }
            if (this.state.selectedBlueprint.tables) {
                data.Tables = this.state.selectedBlueprint.tables.map(t => ({
                    Name: t.name,
                    groupBy: DOMPurify.sanitize(this.state.blueprintTableGroupByColumn?.value),
                    groupByName: DOMPurify.sanitize(this.state.blueprintTableGroupByColumn?.label),
                    groupByType: DOMPurify.sanitize(this.state.blueprintTableGroupByColumn?.type),
                    Columns: this.getColumnsForTable(t).filter(c => this.isBlueprintTableColumnChecked(t, c)).map(c => ({
                        Id: c.id,
                        Title: c.title,
                        Type: c.type,
                        MirrorType: c.mirrorType,
                        MirrorSettings: c.mirrorSettings,
                        width: c.width,
                        settings_str: c.settings_str
                    }))
                }));
            }

            const response = await axios.post('template/createFromBlueprint', data);
            var responseData = response.data;
            if (responseData.result) {
                this.setState({
                    templates: [...this.state.templates, responseData.template],
                    selectedTemplate: responseData.template,
                    mapBlueprintFields: false,
                    templateCreating: false
                });
                this.internalSelectDataSource(this.state.selectedBlueprint.dataSource, 1);
            }
        } catch (error) {
            // Error handling
            console.error("Error creating template from blueprint:", error);
            this.setState({
                // Example of setting an error state or message
                error: "Failed to create template. Please try again.",
                templateCreating: false
            });
        }
    }

    isBlueprintTableColumnChecked(table, column) {
        var id = table.name + '-' + column.id;
        return this.state.blueprintTableSelectedColumns.includes(id);
    }

    handleTemplateBuilderClose(template, templateDefinition) {
        this.setState({ showTemplateBuilder: false });        
        if (template) {
            this.setState({ templates: [...this.state.templates, template] });
            this.setState({ selectedTemplate: template, selectedTemplateDefinition: templateDefinition });
            if (template.finetuneTemplate) {
                this.setState({
                    showCreateAndUploadTemplate: true,
                    defaultTemplateDownloaded: true,
                    finetuneTemplate: true,
                });
            } else {
                this.setMultiStepActiveStep(this.state.currentStep + 1);
            }
        }
    }

    renderTemplateBuilder() {
        return <TemplateBuilder columns={this.props.columns}
            subItemsColumns={this.props.subItemsColumns}
            isReportGenerator={this.props.isReportGenerator}
            dataSource={this.state.dataSource}
            onClose={this.handleTemplateBuilderClose}></TemplateBuilder>;        
    }

    handlePlaceholderClick(placeholder) {
        navigator.clipboard.writeText(placeholder)
            .then(() => {
                //alert('Text copied to clipboard');
                monday.execute("notice", {
                    message: 'Placeholder copied to clipboard',
                    type: "info", // or "error" (red), or "info" (blue)
                    timeout: 2000,
                });
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    }

    renderCreateAndUploadTemplate() {
        if (this.state.defaultTemplateDownloaded) {
            return (
                <Flex align={Flex.align.START} gap={32}>
                    <div className="app-spirit-two-column">
                        {this.state.finetuneTemplate && <p>Your template has been downloaded to your computer.</p>}
                        <p>Now, modify the Microsoft Word template file on your computer and upload it:</p>
                        <Flex gap={16}>
                            <FileUpload Destination={this.state.dataSource} onUploadComplete={this.fileUploadComplete}></FileUpload>
                            <Button kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                                Cancel
                            </Button>
                        </Flex>
                        <br /><br />
                        <p>Tip: Click on a placeholder on the right side to copy it to the clipboard. Then you can paste the placeholder into your Word document. <Link href="https://www.docexport.com/help/placeholders/" text="Help Center" /></p>
                    </div>
                    <div className="app-spirit-two-column">
                        <Box backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR}
                            border={Box.borders.DEFAULT}
                            padding={Box.paddings.LARGE}
                            className="app-spirit-placeholder-list-box"
                            rounded={Box.roundeds.MEDIUM}>
                            <PlaceholderListMonday
                                columns={this.props.columns}
                                subItemsColumns={this.props.subItemsColumns}
                                dataSource={this.state.dataSource}
                                isReportGenerator={this.props.isReportGenerator}
                                onPlaceholderClick={this.handlePlaceholderClick} />
                        </Box>
                    </div>
                </Flex>
            );
        }

        return (
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-two-column">
                    <p>First, download the default template. If you already have a template file ready, choose "Skip".</p>
                    <Flex gap={16}>
                        <Button
                            ariaLabel="Download"
                            loading={this.state.isDownloadingDefaultTemplate}
                            onClick={() => this.downloadDefaultTemplate()}
                        >Download default template</Button>
                        <Button kind={Button.kinds.SECONDARY} onClick={this.handleSkipDownloadDefaultTemplateButtonClick}>
                            Skip
                        </Button>
                        <Button kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                            Cancel
                        </Button>
                    </Flex>
                </div>
                <div className="app-spirit-two-column">
                    <Box backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR} border={Box.borders.DEFAULT} padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}>
                        <p className="app-spirit-explanation-text-top">How do I work with templates?</p>
                        <ol>
                            <li>Download the default template.</li>
                            <li>
                                Customize your template by using placeholders.<br />
                                Placeholders indicate where to insert information from your board into your {this.texts.document}. For example, if you want to add the Item Name to your {this.texts.document}, use the placeholder {"{{Item.Name}}"}.<br />
                                In the default template, you will find a list of all placeholders available for your specific board.
                            </li>
                            <li>Change the design of your template using the formatting options in Word, e.g. insert a logo, add information to the footer, change font, font size, spacing, etc.</li>
                            <li>Upload your customized template using the button on the left.<br />All templates have to be in Microsoft Word format (.docx).</li>
                        </ol>
                        <p className="app-spirit-explanation-text-bottom"><Link href="https://www.docexport.com/help/template/" text="Help Center" /></p>
                    </Box>
                </div>
            </Flex>
        );
    }

    renderContent() {
        if (this.state.showTemplateBuilder)
            return this.renderTemplateBuilder();

        if (this.state.documentError)
            return this.renderDocumentError();
        if (this.state.documentContent)
            return this.renderDocumentContent();

        if (this.state.mapBlueprintFields)
            return this.renderMapBlueprintFields();
        if (this.state.dataSource)
            return this.renderStepContent();

        var recipesContent = "";
        if (this.state.recipes.length > 0)
            recipesContent = this.renderRecipesContent();
        //var blueprintsContent = "";
        //if (this.state.blueprints.length > 0)
        //    blueprintsContent = this.renderBlueprintsContent();

        return (
            <div>
                {recipesContent}
                {/*{blueprintsContent}*/}
                <p>Please choose your starting point:</p>
                <Flex>
                    <div onClick={(e) => this.selectDataSourceClick(e, 'Board')}>
                        <Box
                            className="app-spirit-select-box"
                            border={Box.borders.DEFAULT}
                            rounded={Box.roundeds.MEDIUM}>
                            <img src={this.texts.selectBoardSvg} alt="Select board" />
                            <Flex>
                                <h2>Board level</h2>
                                {this.texts.recommendedDataSource === 'Board' && <Chips readOnly label="Recommended" color={Chips.colors.POSITIVE} />}
                            </Flex>                            
                            <span>{this.texts.dataSourceBoardDescription}</span><br /><br />
                            <span className="gray-text">{this.texts.dataSourceBoardExplanation}</span>
                        </Box>
                    </div>
                    <div onClick={(e) => this.selectDataSourceClick(e, 'SingleItem')}>
                        <Box className="app-spirit-select-box" border={Box.borders.DEFAULT} rounded={Box.roundeds.MEDIUM}>
                            <img src={this.texts.selectSingleItemSvg} alt="Select single item" />
                            <Flex>
                                <h2>{this.texts.dataSourceItemHeader}</h2>
                                {this.texts.recommendedDataSource === 'Item' && <Chips readOnly label="Recommended" color={Chips.colors.POSITIVE} />}
                            </Flex>
                            <span>{this.texts.dataSourceItemDescription}</span><br />
                            <span className="gray-text">{this.texts.dataSourceItemExplanation}</span>
                        </Box>
                    </div>
                </Flex>
                <br />
                <Flex>Need help?&nbsp;
                    <Link href="https://www.docexport.com/help/" text="Help Center" />&nbsp;|&nbsp;
                    <Link icon={Video} href="https://www.youtube.com/watch?v=eAjqsd9ebhk" text="Explainer Video" />
                </Flex>
            </div>
        )
    }

    async previewCreatedDocument() {        
        //console.log(window.location.host);
        // TODO: When available show in viewer in monday.com?
        var urlPath = 'https://' + window.location.host +
            '/document/Download?Id=' + this.state.documentContent.id + '&ForViewer=true&SessionToken=' + window.mondaySessionToken
        monday.execute("openLinkInTab", { url: urlPath });

        //monday.execute("openAppFeatureModal", { urlPath: urlPath }).then((res) => {
        //    var urlPath = '/document/Download';
        //    var urlParams = {
        //        Id: this.state.documentContent.id,
        //        ForViewer: true,
        //        SessionToken: window.mondaySessionToken
        //    };
        //    monday.execute("openAppFeatureModal", { urlPath: urlPath, urlParams: urlParams }).then((res) => {
        //        console.log(res.data);
        //        // The above is a callback to see if a user closed the modal from the inside. This is useful should you want to run some logic within the app window.
        //    });
    }

    async downloadCreatedDocument() {
        await downloadCreatedDocument(this.state.documentContent);
    }

    renderDocumentError() {
        return (
            <div>
                <Box
                    className="app-spirit-document-ready-box"
                    border={Box.borders.DEFAULT}
                    shadow={Box.shadows.MEDIUM}
                    rounded={Box.roundeds.MEDIUM}>
                    <h2>{this.texts.errorGeneratingDocument}</h2>
                    <span>Error: {this.state.documentError.error}</span><br />
                    <br />
                    {this.state.selectedTemplate && (
                        <div>
                            <p>You can adjust your template and then upload a new version to generate the document:</p>
                            <Flex>
                                {(this.state.selectedBlueprint || this.state.selectedTemplateDefinition) && (
                                    <Button className="app-spirit-downloadbutton" kind={Button.kinds.SECONDARY} onClick={() => this.downloadTemplate(this.state.selectedTemplate)}>
                                        Download template
                                    </Button>
                                )}
                                <FileUpload Destination={this.state.selectedTemplate.id}
                                    buttonText="Replace template"
                                    isExecuting={this.state.executing}
                                    onUploadComplete={this.fileUploadCompleteTemplateReplace}></FileUpload>
                            </Flex>
                            <br />
                        </div>
                    )}
                    <br />
                    <Flex>Need help?&nbsp;
                        <Link href="https://www.docexport.com/help/template/" text="Help Center" />&nbsp;|&nbsp;
                        <Link href="https://www.docexport.com/contact/" text="Contact us" />
                    </Flex>
                    <br />
                </Box>
                <br />
                <Button kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                    Close
                </Button>
            </div>
        );
    }

    renderDocumentContent() {
        //console.log(this.state);

        let addIntegrationInstructions = (
            <Button kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                Close
            </Button>
        );
        if (this.state.dataSource === "SingleItem")
            addIntegrationInstructions = this.renderAddIntegrationInstructions();

        const fullThumbnailUrl = '/document/Thumbnail?Id=' + this.state.documentContent.id +
            '&SessionToken=' + window.mondaySessionToken;
        return (
            <div>
                <Box
                    className="app-spirit-document-ready-box"
                    border={Box.borders.DEFAULT}
                    shadow={Box.shadows.MEDIUM}
                    rounded={Box.roundeds.MEDIUM}>
                    <Flex className="app-spirit-doc-ready-flex">
                        <img className="app-spirit-doc-preview-img" src={fullThumbnailUrl} alt="Preview" onClick={this.previewCreatedDocument}></img>
                        <div className="app-spirit-doc-ready-text">
                            <h2>{this.texts.documentCreatedSuccessHeader}</h2>
                            <span>{this.state.documentContent.name}</span><br />
                            {this.state.documentCreatedMessage &&
                                <p><span className="app-spirit-important-hint">{this.state.documentCreatedMessage}</span></p>}
                            {this.state.documentContent.toolTip &&
                                <p style={{ color: this.state.documentContent.iconName === 'Warning' ? 'red' : 'inherit' }}>
                                    {this.state.documentContent.toolTip}
                                </p>}
                            <br />
                            <Button className="app-spirit-downloadbutton" onClick={this.downloadCreatedDocument}>
                                {this.texts.downloadDocument}
                            </Button>
                            {(this.state.documentType === 'Pdf') && <Button rightIcon={ExternalPage} className="app-spirit-downloadbutton" kind={Button.kinds.SECONDARY} onClick={this.previewCreatedDocument}>
                                Open preview
                            </Button>}
                            <br />
                            <br />
                            <Flex>{this.texts.Document} is not like you expected?&nbsp;
                                <Link href="https://www.docexport.com/help/template/" text="Help Center" />&nbsp;|&nbsp;
                                <Link href="https://www.docexport.com/contact/" text="Contact us" />
                            </Flex>
                            <br />
                            {this.state.selectedTemplate && <div>
                                <p>Not quite happy yet? Adjust your template and simply upload the new version here to regenerate the {this.texts.document}:</p>
                                <Flex>
                                    {(this.state.selectedBlueprint || this.state.selectedTemplateDefinition) && (
                                        <Button className="app-spirit-downloadbutton" kind={Button.kinds.SECONDARY} onClick={() => this.downloadTemplate(this.state.selectedTemplate)}>
                                            Download template
                                        </Button>
                                    )}
                                    <FileUpload Destination={this.state.selectedTemplate.id}
                                        buttonText="Replace template"
                                        buttonKind={Button.kinds.SECONDARY}
                                        isExecuting={this.state.executing}
                                        onUploadComplete={this.fileUploadCompleteTemplateReplace}></FileUpload>
                                </Flex>
                                <br />
                            </div>}
                        </div>
                    </Flex>
                </Box>
                <br />
                {addIntegrationInstructions}
            </div>
        );
    }

    closeAssistant() {
        this.setState({
            dataSource: '',
            documentContent: undefined,
            documentError: undefined,
            recipeName: '',
            fileName: '',
            saveAsRecipe: false,
            selectedTemplate: null,
            fileToColumn: false,
            selectedFileToColumn: undefined,
            sendEmailToMe: false,
            sendEmailToColumn: false,
            sendEmailToCustomAddresses: false,
            customEmailAddresses: '',
            showTipseen: !this.tipseenDismissed,
            //tipseenDismissed: this.tipseenDismissed,
            useLetterpaper: false,
            selectedItem: undefined,
            selectedAttachmentColumn: undefined,
            attachFilesFromColumn: false,
            mapBlueprintFields: false,
            selectedBlueprint: undefined,
            templateCreating: false,
            selectedSendEmailColumn: undefined,
            showTemplateBuilder: false,
            showCreateAndUploadTemplate: false,
            defaultTemplateDownloaded: false,
            finetuneTemplate: false,
            selectedTemplateDefinition: null,
        });        
        localStorage.removeItem(this.localStorageStateIdentifier);
        //console.log('Close assistant ' + this.localStorageStateIdentifier);
        this.props.onAssistantModeChanges(false);
    }

    renderStepContent() {
        let stepContent = "";
        //if (this.state.currentStep === 0)
        //    stepContent = this.renderSelectDataStep();
        if (this.state.currentStep === 0) {
            if (this.state.showCreateAndUploadTemplate)
                stepContent = this.renderCreateAndUploadTemplate();
            else if (this.props.isReportGenerator)
                stepContent = this.renderReportSelectTemplateStep();
            else
                stepContent = this.renderSelectTemplateStep();
        }
        if (this.state.currentStep === 1)
            stepContent = this.renderSelectActionsStep();
        if (this.state.currentStep === 2)
            stepContent = this.renderMoreSettingsStep();
        if (this.state.currentStep === 3)
            stepContent = this.renderInstructionsStep();

        return (
            <div>
                {/*<IconButton                    */}
                {/*    className="app-spirit-close-button"*/}
                {/*    icon={Close}*/}
                {/*    onClick={this.closeAssistant}*/}
                {/*/>*/}
                <MultiStepIndicator
                    steps={this.state.steps}
                    textPlacement={MultiStepIndicator.textPlacements.VERTICAL}
                    onClick={this.handleMultiStepClick}
                />
                <p><br /></p>
                {stepContent}
            </div>);
    }

    //renderSelectDataStep() {
    //    var recipesContent = "";
    //    if (this.state.recipes.length > 0)
    //        recipesContent = this.renderRecipesContent();
    //    return (
    //        <div>
    //            {recipesContent}
    //            <p>You want to create a document containing data from</p>
    //            <Flex className="app-spirit-flex">
    //                <Button onClick={(e) => this.selectDataSourceClick(e, 'Board')}>
    //                    The entire board
    //                </Button>
    //            </Flex>
    //            {/*<Flex className="app-spirit-flex">*/}
    //            {/*    <Button onClick={(e) => this.selectDataSourceClick(e, 'SelectedItems')}>*/}
    //            {/*        Selected or filtered items (5 selected)*/}
    //            {/*    </Button>*/}
    //            {/*</Flex>*/}
    //            <Flex className="app-spirit-flex">
    //                <Button onClick={(e) => this.selectDataSourceClick(e, 'SingleItem')}>
    //                    Single item (with subitems)
    //                </Button>
    //            </Flex>
    //        </div>
    //    );
    //}

    renderRecipesContent() {
        var modifiers = [
            {
                name: 'preventOverflow',
                options: {
                    mainAxis: false
                }
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: []
                }
            }
        ];

        //console.log('showTipseen: ' + this.state.showTipseen);
        var showTipseen = this.state.showTipseen && (this.state.recipes.length === 1);

        return (
            <div>
                <div className="app-spirit-little-space-bottom" onClick={this.removeMondayStorageEntry}>Use existing {this.texts.DocExport} board recipe</div>
                <Flex wrap gap={8}>
                    {this.state.recipes.map(r =>
                        showTipseen ?
                            <Tipseen key={r.id}
                                modifiers={modifiers}
                                position={Tipseen.positions.RIGHT}
                                content={
                                    <TipseenContent isSubmitHidden={true} isDismissHidden={false} title="Shortcut" onDismiss={this.dismissTipseen}>
                                        This is a board recipe that you saved before. You can use it to generate {this.texts.documents} with the same template and settings again.
                                    </TipseenContent>
                                }>
                                {this.renderExistingRecipeButton(r)}
                            </Tipseen>
                            :
                            this.renderExistingRecipeButton(r)

                    )}
                </Flex>
            </div>
        );
    }

    renderBlueprintsContent(withBoardPrint) {
        var blueprints = this.state.blueprints;
        if (this.state.dataSource && blueprints)
            blueprints = blueprints.filter(b => b.dataSource === this.state.dataSource);        
        if (!this.boardHasSubItemsColumn())
            blueprints = blueprints.filter(b => b.needsSubitems !== true);
        if (!withBoardPrint)
            blueprints = blueprints.filter(b => b.filename !== "DocExport_Board_Print.docx");
        if (blueprints.length === 0)
            return null;        
        return (
            <div>
                <div><br /><hr /></div>
                <p>Or use one of our predefined templates:</p>
                <Flex wrap gap={8}>
                    {blueprints.map(b =>
                        <Tooltip key={b.name} content={b.description}>
                            <Button key={b.name} kind="secondary" size="small"
                            loading={this.state.executing && (this.state.executingRecipeId === b.id)}
                            onClick={(e) => this.handleUseBlueprintButtonClick(e, b)}>
                            {b.name}
                            </Button>
                        </Tooltip>
                    )}
                </Flex>                
            </div>
        );
    }

    renderExistingTemplatesContent() {
        var dataSource = "Board";
        if (this.state.dataSource === "SingleItem")
            dataSource = "Item";
        let templates = this.state.templates.filter(t => t.dataSource === dataSource);
        if (templates.length === 0)
            return null;
        return (
            <div>
                <br /><hr />
                <p>Or use one of your existing templates:</p>
                <Flex wrap gap={8}>
                    {templates.map(t =>
                        <Button key={t.id} kind="secondary" size="small"
                            onClick={(e) => this.handleUseTemplateButtonClick(e, t)}>
                            {t.name}
                        </Button>
                    )}
                </Flex>
            </div>
        );
    }

    handleUseTemplateButtonClick(event, template) {
        this.setState({ selectedTemplate: template });
        this.setMultiStepActiveStep(this.state.currentStep + 1);
    }

    renderExistingRecipeButton(recipe) {
        return (
            <Button key={recipe.id}
                loading={this.state.executing && (this.state.executingRecipeId === recipe.id)}
                onClick={(e) => this.handleUseRecipeButtonClick(e, recipe)}>
                {recipe.name}
            </Button>
        );
    }

    dismissTipseen() {
        console.log('Dismiss');
        this.tipseenDismissed = true;
        this.setState({ showTipseen: false });
        monday.storage.instance.setItem(this.dismissTipseenIdentifier, this.tipseenDismissed);
    }

    async handleUseBoardPrintBlueprintButtonClick(event) {
        let blueprint = this.state.blueprints.find(b => b.filename === "DocExport_Board_Print.docx");
        await this.handleUseBlueprintButtonClick(event, blueprint);
    }

    async handleUseBlueprintButtonClick(event, blueprint) {
        axios.post('template/blueprintSelected', {
            blueprintName: blueprint.name,
            boardId: this.mondayContext.boardId,
            sessionToken: window.mondaySessionToken
        })
            .then(response => console.log('Success:', response.data))
            .catch(error => console.error('Error:', error));

        if (blueprint.needsSubitems)
            this.loadSubItemsColumnData();

        // Auto Mapping
        var columnMapping = {};
        blueprint.groups.forEach(g => {
            var columns = this.getColumnsForMappingForAGroup(g);
            if (!columns)
                return;

            g.fields.forEach(f => {

                if (f.lookupValues) {
                    if (f.mapMode === "FirstInLookupValues") {
                        //f.selectedColumn = f.lookupValues[0];
                        columnMapping[f.name] = f.lookupValues[0];
                    }                        
                    return;
                }

                // Initialize an array to hold the required types
                let typesNeeded = [];

                // Check if dataNeeded starts with "Type:" and extract types if it does
                if (f.dataNeeded.startsWith("Type:")) {
                    typesNeeded = f.dataNeeded.split(':')[1].split(',');
                }

                var column = columns.find(c =>
                    (c.title.toLowerCase() === f.displayName.toLowerCase() ||
                        (f.possibleNames && f.possibleNames.some(name => name.toLowerCase() === c.title.toLowerCase()))) &&
                    (typesNeeded.length === 0 || typesNeeded.includes(c.type) || typesNeeded.includes(c.mirrorType)));

                if (!column && (f.mapMode === "FirstWithType")) {
                    column = columns.find(c =>
                        (typesNeeded.length === 0 || typesNeeded.includes(c.type) || typesNeeded.includes(c.mirrorType)));
                }

                if (column) {
                    //f.selectedColumn = { value: column.id, label: column.title };
                    columnMapping[f.name] = { value: column.id, label: column.title };
                }
            });
        });

        var blueprintTableSelectedColumns = [...this.state.blueprintTableSelectedColumns];
        if (blueprint.tables) {
            blueprint.tables.forEach(t => {
                var columns = this.getColumnsForTable(t);
                for (let i = 0; i < columns.length && i < 5; i++) {
                    var id = t.name + '-' + columns[i].id;
                    if (!blueprintTableSelectedColumns.includes(id))
                        blueprintTableSelectedColumns.push(id);
                }
                //this.setState({ blueprintTableSelectedColumns: blueprintTableSelectedColumns });
            });
        }

        this.setState({
            mapBlueprintFields: true,
            selectedBlueprint: blueprint,
            blueprintMappedColumns: columnMapping,
            blueprintTableSelectedColumns: blueprintTableSelectedColumns
        },
            () => this.updateHasMandatoryFieldsNotAssigned());
        this.props.onAssistantModeChanges(true);
    }    

    async handleUseRecipeButtonClick(event, recipe) {        
        const data = {
            accountId: this.mondayContext.account?.id,
            boardId: this.mondayContext.boardId,
            sessionToken: window.mondaySessionToken,
            userCountryCode: this.mondayContext.user.countryCode,
            userLanguage: this.mondayContext.user.currentLanguage,
            recipeId: recipe.id,
            requestId: this.generateUniqueID(),
            generateThumbnail: true,
        };
        if (recipe.itemFilter) {
            this.setState({ useFilter: true, mondayFilterText: recipe.itemFilter }, async () => {
                await this.generateDocument(data);
            });            
        }
        else
            await this.generateDocument(data);
        //this.props.onAssistantModeChanges(true);
    }

    async downloadTemplate(template) {
        await downloadExistingTemplate(template, window.mondaySessionToken);
    }

    renderSelectTemplateStep() {
        var dataSource = "Board";
        if (this.state.dataSource === "SingleItem")
            dataSource = "Item";
        let templates = this.state.templates.filter(t => t.dataSource === dataSource);

        var blueprintsContent = "";
        if (this.state.blueprints.length > 0)
            blueprintsContent = this.renderBlueprintsContent(true);

        var existingTemplatesContent = "";
        if (templates.length > 0)
            existingTemplatesContent = (
                <div>
                    {this.towerAppInfos.isDebug && <Button onClick={this.handleTemplateBuilderClick}>{this.texts.useTemplateBuilder}</Button>}
                    <p>First, download the default template:</p>
                    <SplitButton
                        children="Download default template"
                        loading={this.state.isDownloadingDefaultTemplate}
                        onClick={() => this.downloadDefaultTemplate()}
                        secondaryDialogContent={<SplitButtonMenu id="split-menu">
                        {templates.map(t =>
                            <MenuItem key={t.id} title={t.name} onClick={() => this.downloadTemplate(t)} />
                        )}
                    </SplitButtonMenu>} />
                    <p>Then, modify it on your computer and upload it:</p>
                    <FileUpload Destination={this.state.dataSource} onUploadComplete={this.fileUploadComplete}></FileUpload>
                    <br /><hr />
                    <p>Or use an existing template:</p>
                    {templates.map(t =>
                        <RadioButton key={t.id} text={t.name} checked={this.state.selectedTemplate === t} onSelect={(e) => this.handleTemplateSelectRadio(t)} />
                    )}
                    <br />
                    <Flex>
                        <Button disabled={!this.state.selectedTemplate} onClick={this.handleButtonClick}>
                            Next
                        </Button>
                        <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                            Cancel
                        </Button>
                    </Flex>
                    {blueprintsContent}
                    <br />
                </div>
            );
        else
            existingTemplatesContent = (
                <div>
                    <p>First, download the default template:</p>
                    <Button
                        ariaLabel="Download"
                        loading={this.state.isDownloadingDefaultTemplate}                        
                        onClick={() => this.downloadDefaultTemplate()}
                    >Download default template</Button>
                    <p>Then, modify it on your computer and upload it:</p>
                    <Flex>
                        <FileUpload Destination={this.state.dataSource} onUploadComplete={this.fileUploadComplete}></FileUpload>
                        <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                            Cancel
                        </Button>
                    </Flex>
                    {blueprintsContent}
                    {!this.props.isReportGenerator && (<p>I am not sure yet, I just want to run a quick test: <Link onClick={this.handleCreateTemplateFromDefaultTemplateClick} text="Give me a test template"></Link></p>)}
                    <br />
                </div>
            );            
        return (
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-two-column">
                    {existingTemplatesContent}
                </div>
                <div className="app-spirit-two-column">
                    <Box backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR} border={Box.borders.DEFAULT} padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}>
                        <p className="app-spirit-explanation-text-top">How do I work with templates?</p>
                        <ol>
                            <li>
                                Download the default template.</li>
                            <li>
                                Customize your template by using placeholders.<br />
                                Placeholders indicate where to insert information from your board into your {this.texts.document}. For example, if you want to add the Item Name to your {this.texts.document}, use the placeholder {"{{Item.Name}}"}.<br />
                                In the default template, you will find a list of all placeholders available for your specific board.
                            </li>
                            <li>Change the design of your template using the formatting options in Word, e.g. insert a logo, add information to the footer, change font, font size, spacing, etc.</li>
                            <li>Upload your customized template using the button on the left.<br />All templates have to be in Microsoft Word format (.docx).</li>
                        </ol>
                        <p className="app-spirit-explanation-text-bottom"><Link href="https://www.docexport.com/help/template/" text="Help Center" /></p>
                        {/*{placeholders.map(p => <p>{p.name} - {p.text}</p>) }*/}
                    </Box>
                </div>
            </Flex>
        );
    }

    renderReportSelectTemplateStep() {
        var blueprintsContent = "";
        if (this.state.blueprints.length > 0)
            blueprintsContent = this.renderBlueprintsContent(false);
        //let existingTemplatesContant = this.renderExistingTemplatesContent();

        return (
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-two-column">
                    <p>Build a report with the Report Builder:</p>
                    <Button
                        onClick={this.handleTemplateBuilderClick}>
                        Use Report Builder
                    </Button>
                    {this.state.dataSource === 'Board' && <div>
                        <br /><hr />
                        <p>Or export a grouped and filtered Board View:</p>
                        <Button
                            kind={Button.kinds.SECONDARY}
                            onClick={this.handleUseBoardPrintBlueprintButtonClick}>
                            Board Print
                        </Button>
                    </div>}
                    {blueprintsContent}
                    <div>
                        <br /><hr />
                        <p>Or create and upload a new individual Microsoft Word template:</p>
                        <Flex>
                            <Button kind="secondary"
                                onClick={this.handleCreateAndUploadTemplateClick}>
                                Create/Upload Word template
                            </Button>
                            <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                                Cancel
                            </Button>
                        </Flex>
                    </div>
                </div>
                <div className="app-spirit-two-column">
                    <Box backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR} border={Box.borders.DEFAULT} padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}>
                        <p className="app-spirit-explanation-text-top">How do I create a report?</p>
                        <ul>
                            <li className="app-spirit-little-space-bottom"><b>Report Builder:</b><br />Create your report using various elements in the Report Builder, including {this.state.dataSource === 'Board' && (<>charts and </>)}tables.</li>
                            {this.state.dataSource === 'Board' && <li className="app-spirit-little-space-bottom"><b>Board Print:</b><br />Export your board view in tabular format, grouped and filtered based on your own criteria, e.g. “all  tasks that are overdue grouped by status.”</li>}
                            <li className="app-spirit-little-space-bottom"><b>Predefined reports:</b><br />Use a predefined report template and customize it in the next steps.</li>
                            <li className="app-spirit-little-space-bottom"><b>
                                Create your own template from scratch</b><br />Design your own Word template by using placeholders and upload it.
                                <Link href="https://www.docexport.com/help/template/" text="Help Center" />
                            </li>
                        </ul>                        
                    </Box>
                </div>
            </Flex>
        );
    }

    async handleCreateTemplateFromDefaultTemplateClick(event) {
        event.preventDefault();

        var boardData = this.mondayColumnData.boards[0];
        var data = {
            BoardData: boardData,
            SubItemsBoardData: this.mondaySubItemsColumnData?.boards[0]
        };
        var url = '/template/CreateFromDefaultTemplate?DataSource=' + this.state.dataSource +
            '&BoardId=' + this.mondayContext.boardId +
            '&SessionToken=' + window.mondaySessionToken;
        var response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.result) {
            this.setState({ templates: [...this.state.templates, responseData.template] });
            this.setState({ selectedTemplate: responseData.template });
            this.setMultiStepActiveStep(this.state.currentStep + 1);
        }
    }

    handleTemplateSelectRadio(template) {
        this.setState({ selectedTemplate: template });
    }

    async downloadDefaultTemplate() {
        this.setState({ isDownloadingDefaultTemplate: true });
        var boardData = this.mondayColumnData.boards[0];
        var data = {
            BoardData: boardData,
            SubItemsBoardData: this.mondaySubItemsColumnData?.boards[0]
        };
        var url = '/template/DownloadDefault?DataSource=' + this.state.dataSource +
            '&SessionToken=' + window.mondaySessionToken;

        //const response = await axios.post(url, data);
        //console.log(response);
        //var blob = await response.blob();

        // See https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
        var response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    //    console.log(response);
    //    var blob = await response.blob();
    //    console.log(blob);
    //    download(blob);
        await initiateDownloadFromFetchResponse(response);
        this.setState({ isDownloadingDefaultTemplate: false, defaultTemplateDownloaded: true });
    }

    handleFileToColumnCheckboxChange(event) {
        //console.log(this.state);
        //console.log(this.state.fileToColumn);
        this.setState({ fileToColumn: !this.state.fileToColumn });
    }

    handleSendEmailToMeCheckboxChange(event) {
        //console.log(this.state);
        //console.log(this.state.sendEmailToMe);
        this.setState({ sendEmailToMe: !this.state.sendEmailToMe });
    }

    handleSendEmailToColumnCheckboxChange(event) {
        //console.log(this.state);
        //console.log(this.state.sendEmailToColumn);
        this.setState({ sendEmailToColumn: !this.state.sendEmailToColumn });
    }

    handleSendEmailToCustomAddressesCheckboxChange(event) {
        //console.log(this.state);
        //console.log(this.state.sendEmailToCustomAddresses);
        this.setState({ sendEmailToCustomAddresses: !this.state.sendEmailToCustomAddresses });
    }

    handleAttachFilesFromColumnCheckboxChange(event) {
        this.setState({ attachFilesFromColumn: !this.state.attachFilesFromColumn });
    }

    renderSelectActionsStep() {
        var actions = undefined;
        var sendEmailAction = undefined;
        var explanationSendEmailToColumn = undefined;
        var explanationFileToColumn = undefined;
        var additionalSettings = "";
        if (this.state.dataSource === "SingleItem") {
            let fileToColumnSelect = this.renderFileToColumnSelect();
            actions = (
                <Flex className="app-spirit-flex">
                    <Checkbox className="app-spirit-checkbox" disabled={this.state.hasNoFileToColumn} onChange={this.handleFileToColumnCheckboxChange} checked={this.state.fileToColumn}
                        label="Save to a file column:"
                    />
                    {fileToColumnSelect}
                </Flex>
            );
            explanationFileToColumn = <li>Save the {this.texts.document} to a file column in your board.<br />
                To do this, select an existing column (type “Files”) in your board or create a new column (type “Files”) in advance.</li>;

            if (!this.props.isReportGenerator) {
                let sendEmailToColumnSelect = this.renderSendEmailToColumnSelect();
                let sendEmailToColumnTemplateSelect = this.renderSendEmailToColumnTemplateSelect();
                sendEmailAction = (
                    <Flex className="app-spirit-flex">
                        <Checkbox className="app-spirit-checkbox" disabled={this.state.hasNoSendEmailColumn} onChange={this.handleSendEmailToColumnCheckboxChange} checked={this.state.sendEmailToColumn}
                            label="Send via email to email address(es) in column:"
                        />
                        {sendEmailToColumnSelect}
                        {sendEmailToColumnTemplateSelect}
                    </Flex>
                );
                explanationSendEmailToColumn = <li>Send it to the email address(es) contained in a specific column.<br />
                    For example, if your board contains a column with the email address of your customer, you can select this column from the dropdown, so that the customer receives the document via email.</li>;

                if (!this.state.hasNoFileToColumn && this.towerAppInfos && this.towerAppInfos.hasEmailTemplates &&
                    this.state.sendEmailToColumn) {
                    var selectedFileColumnId = "";
                    if (this.state.fileToColumn)
                        selectedFileColumnId = this.state.selectedFileToColumn?.value;
                    var attachmentColumns = this.state.fileToColumns.filter(c => c.value !== selectedFileColumnId);
                    if (attachmentColumns.length > 0) {
                        additionalSettings = (
                            <div>
                                <p><br /></p>
                                <ExpandCollapse
                                    className=""
                                    title="Advanced settings">
                                    <Flex className="app-spirit-flex">
                                        <Checkbox className="app-spirit-checkbox" onChange={this.handleAttachFilesFromColumnCheckboxChange} checked={this.state.attachFilesFromColumn}
                                            label="Additionally attach files to emails from:"
                                        />
                                        <Dropdown
                                            className="app-spirit-dropdown"
                                            onBlur={function noRefCheck() { }}
                                            onChange={this.handleAttachmentColumnChange}
                                            onClear={function noRefCheck() { }}
                                            onFocus={function noRefCheck() { }}
                                            onInputChange={function noRefCheck() { }}
                                            onMenuClose={function noRefCheck() { }}
                                            onMenuOpen={function noRefCheck() { }}
                                            onOptionRemove={function noRefCheck() { }}
                                            onOptionSelect={function noRefCheck() { }}
                                            openMenuOnFocus={function noRefCheck() { }}
                                            value={this.state.selectedAttachmentColumn}
                                            options={attachmentColumns}
                                            clearable={false}
                                            placeholder={this.texts.attachmentColumn}
                                        />
                                    </Flex>
                                </ExpandCollapse>
                            </div>
                        );
                    }
                }
            }
        }

        var sendEmailToMeTemplateSelect = this.renderSendEmailToMeTemplateSelect();
        var sendEmailToCustomAddressesInput = this.renderSendEmailToCustomAddressesInput();
        var sendEmailToCustomAddressesTemplateSelect = this.renderSendEmailToCustomAddressesTemplateSelect();
        return (
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-two-column">
                    <p>{this.texts.stepActionsTitle}</p>
                    <div title={this.texts.generatedDocumentsCanAlwaysBeFoundHere}>
                        <Checkbox onChange={this.handleCheckboxChange} checked={true} disabled={true}
                            label={this.texts.saveInApp}
                        />
                    </div>
                    {actions}
                    <Flex>
                        <Checkbox className="app-spirit-checkbox" onChange={this.handleSendEmailToMeCheckboxChange} checked={this.state.sendEmailToMe}
                            label={this.texts.sendToMeCheckboxText}
                        />
                        {sendEmailToMeTemplateSelect}
                    </Flex>
                    {sendEmailAction}
                    <Checkbox className="app-spirit-checkbox" onChange={this.handleSendEmailToCustomAddressesCheckboxChange} checked={this.state.sendEmailToCustomAddresses}
                        label="Send via email to email address(es)"
                    />
                    <Flex className="app-spirit-flex">
                        {sendEmailToCustomAddressesInput}
                        {sendEmailToCustomAddressesTemplateSelect}
                    </Flex>
                    {/*<Checkbox onChange={this.handleCheckboxChange}*/}
                    {/*    label="Send as letter"*/}
                    {/*/>*/}
                    {/*<Checkbox onChange={this.handleCheckboxChange}*/}
                    {/*    label="Call webhook"*/}
                    {/*/>*/}
                    {additionalSettings}
                    <p><br /></p>
                    <Flex>
                        <Button onClick={this.handleButtonClick}>
                            Next
                        </Button>
                        <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                            Cancel
                        </Button>
                    </Flex>
                </div>
                <div className="app-spirit-two-column">
                    <Box backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR} border={Box.borders.DEFAULT} padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}>
                        <p className="app-spirit-explanation-text-top">How do I work with actions?</p>
                        <p>{this.texts.allDocumentsAreSavedInAppText}</p>
                        <p className="app-spirit-explanation-text-bottom">Additionally, you can
                            <ol>
                                {explanationFileToColumn}
                                <li>{this.texts.explanationSendEmailToMe}</li>
                                {explanationSendEmailToColumn}
                                <li>Send it to others via email by adding one or more email addresses manually, separated by a semicolon (e.g. hello@company.com; tom@company.com)<br /></li>
                            </ol>
                        </p>
                    </Box>
                </div>
            </Flex>
        );
    }

    renderSendEmailToMeTemplateSelect() {
        if (!this.state.sendEmailToMe)
            return undefined;
        if (!this.state.emailTemplates)
            return undefined;
        if (this.state.emailTemplates.length === 0)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleSendEmailToMeTemplateChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.sendEmailToMeTemplate}
                options={this.state.emailTemplates}
                clearable={false}
                placeholder={this.texts.sendEmailTemplate}
            />
        );
    }

    renderSendEmailToColumnTemplateSelect() {
        if (!this.state.sendEmailToColumn)
            return undefined;
        if (!this.state.emailTemplates)
            return undefined;
        if (this.state.emailTemplates.length === 0)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleSendEmailToColumnTemplateChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.sendEmailToColumnTemplate}
                options={this.state.emailTemplates}
                clearable={false}
                placeholder={this.texts.sendEmailTemplate}
            />
        );
    }

    renderSendEmailToCustomAddressesTemplateSelect() {
        if (!this.state.sendEmailToCustomAddresses)
            return undefined;
        if (!this.state.emailTemplates)
            return undefined;
        if (this.state.emailTemplates.length === 0)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleSendEmailToCustomAddressesTemplateChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.sendEmailToCustomAddressesTemplate}
                options={this.state.emailTemplates}
                clearable={false}
                placeholder={this.texts.sendEmailTemplate}
            />
        );
    }

    renderSendEmailToCustomAddressesInput() {
        if (!this.state.sendEmailToCustomAddresses)
            return undefined;
        return (
            <TextField
                wrapperClassName="app-spirit-textfield-emailaddresses"
                placeholder="Email addresses separated by semicolon"
                value={this.state.customEmailAddresses}
                onChange={this.handleSendEmailCustomAddressesChange}
            />
        );
    }

    handleAttachmentColumnChange(selectedAttachmentColumn) {
        this.setState({ ...this.state, selectedAttachmentColumn: selectedAttachmentColumn });        
    }

    handleFileToColumnChange(selectedFileToColumn) {
        var fileToColumn = this.state.fileToColumn;
        if (selectedFileToColumn)
            fileToColumn = true;
        this.setState({ ...this.state, selectedFileToColumn: selectedFileToColumn, fileToColumn: fileToColumn });
        //monday.storage.instance.setItem('FileToColumn', selectedFileToColumn.value);
    }

    handleSendEmailToColumnChange(selectedSendEmailColumn) {
        var sendEmailToColumn = this.state.sendEmailToColumn;
        if (selectedSendEmailColumn)
            sendEmailToColumn = true;
        this.setState({ ...this.state, selectedSendEmailColumn: selectedSendEmailColumn, sendEmailToColumn: sendEmailToColumn });
        //monday.storage.instance.setItem('FileToColumn', selectedFileToColumn.value);
    }

    handleSendEmailToMeTemplateChange(sendEmailToMeTemplate) {
        this.setState({ ...this.state, sendEmailToMeTemplate: sendEmailToMeTemplate });
    }

    handleMapBlueprintColumnChange(mapBlueprintColumn, blueprintField, blueprintGroup) {
        //this.updateBlueprintSelectedColumn(blueprintGroup.name, blueprintField.name, mapBlueprintColumn);
        this.setState({ ...this.state, blueprintMappedColumns: { ...this.state.blueprintMappedColumns, [blueprintField.name]: mapBlueprintColumn } },
            () => { this.updateHasMandatoryFieldsNotAssigned(); });
    }

    getMappedColumnForBlueprintField(blueprintField, blueprintGroup) {
        return this.state.blueprintMappedColumns[blueprintField.name];
    }

    //updateBlueprintSelectedColumn(groupName, fieldName, newValue) {
    //    this.setState((prevState) => {
    //        // Copy the entire selectedBlueprint object
    //        const { selectedBlueprint } = prevState;

    //        // Map through the groups to find the one we want to update
    //        const updatedGroups = selectedBlueprint.groups.map((group) => {
    //            // Check if this is the group we want to update
    //            if (group.name === groupName) {
    //                // Map through the fields to update the specific field
    //                const updatedFields = group.fields.map((field) =>
    //                    field.name === fieldName ? { ...field, selectedColumn: newValue } : field
    //                );

    //                // Return a new group object with the updated fields
    //                return { ...group, fields: updatedFields };
    //            }

    //            // For groups that don't match, return them unchanged
    //            return group;
    //        });

    //        // Return the new state with the updated groups array
    //        return {
    //            selectedBlueprint: {
    //                ...selectedBlueprint,
    //                groups: updatedGroups,
    //            },
    //        };
    //    }, () => { this.updateHasMandatoryFieldsNotAssigned(); });
    //}

    handleSendEmailToColumnTemplateChange(sendEmailToColumnTemplate) {
        this.setState({ ...this.state, sendEmailToColumnTemplate: sendEmailToColumnTemplate });
    }

    handleSendEmailToCustomAddressesTemplateChange(sendEmailToCustomAddressesTemplate) {
        this.setState({ ...this.state, sendEmailToCustomAddressesTemplate: sendEmailToCustomAddressesTemplate });
    }

    handleSelectedItemChange(selectedItem) {
        this.setState({ selectedItem: selectedItem });
    }

    renderSendEmailToColumnSelect() {
        //if (!this.state.sendEmailToColumn)
        //    return undefined;
        if (!this.state.sendEmailColumns)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleSendEmailToColumnChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.selectedSendEmailColumn}
                options={this.state.sendEmailColumns}
                clearable={false}
                placeholder={this.texts.sendEmailToColumn}
            />
        );
    }

    renderFileToColumnSelect() {
        //if (!this.state.fileToColumn)
        //    return undefined;
        if (!this.state.fileToColumns)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleFileToColumnChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.selectedFileToColumn}
                options={this.state.fileToColumns}
                clearable={false}
                placeholder={this.texts.fileToColumn}
            />
        );
    }

    renderAddIntegrationInstructions() {
        return (
            <div>
                <hr />
                <Flex align={Flex.align.START} gap={32}>
                    <div className="app-spirit-two-column">
                        <h2>Create your own integration</h2>
                        <span>Add an automation/integration to your board:</span>
                        <ol>
                            <li>Click on "Integrate" (top right corner)</li>
                            <li>Search for "{this.texts.DocExport}" and select it</li>
                            <li>Select the integration recipe that suits your use case</li>
                            <li>
                                Fill in the parameters<br />
                                Example: Changing the Status to Signed, create a new {this.texts.document} based on the {this.texts.DocExportRecipe} "{this.state.recipeName}".
                            </li>
                            <li>Add the integration to your board. All done. If you now change the status/click a specific button or a certain date is reached, a {this.texts.document} will automatically be created.</li>
                        </ol>
                        <Button kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                            Close
                        </Button>
                    </div>
                    <div className="app-spirit-two-column">
                        <p>&nbsp;</p>
                        <img className="responsive-image" src="/Screenshot_Step4_Instructions.png" alt="Add integration" />
                    </div>
                </Flex>
            </div>);
    }

    renderInstructionsStep() {  // TODO!
        let addIntegrationInstructions = this.renderAddIntegrationInstructions();
        return (
            <div>
                <h4>You successfully created the {this.texts.DocExportRecipe} "{this.state.recipeName}" 🎉</h4>
                <div className="app-spirit-little-space-bottom">You can generate a {this.texts.document} directly here to preview your settings:</div>
                <Flex>
                    <Dropdown
                        className="app-spirit-dropdown"
                        onBlur={function noRefCheck() { }}
                        onChange={this.handleSelectedItemChange}
                        onClear={function noRefCheck() { }}
                        onFocus={function noRefCheck() { }}
                        onInputChange={function noRefCheck() { }}
                        onMenuClose={function noRefCheck() { }}
                        onMenuOpen={function noRefCheck() { }}
                        onOptionRemove={function noRefCheck() { }}
                        onOptionSelect={function noRefCheck() { }}
                        openMenuOnFocus={function noRefCheck() { }}
                        value={this.state.selectedItem}
                        options={this.itemsForSelection}
                        clearable={false}
                        placeholder={this.texts.selectItem}
                    />
                    <Button disabled={!this.state.selectedItem} loading={this.state.executing} onClick={this.generateForItemButtonClick}>
                        {this.texts.generateDocument}
                    </Button>
                </Flex>
                <br />
                {addIntegrationInstructions}
            </div>
        );
    }

    changeDocumentType(documentType) {
        this.setState({ documentType: documentType });
    }

    renderMoreSettingsStep() {
        var buttonContent = (
            <Button loading={this.state.executing} onClick={this.generateButtonClick}>
                {this.texts.generateDocument}
            </Button>
        );
        var saveAsRecipeContent = (
            <Flex>
                <Checkbox className="app-spirit-checkbox" onChange={this.handleSaveRecipeCheckboxChange} checked={this.state.saveAsRecipe}
                    label={this.texts.saveThisRecipe}
                />
                {this.state.saveAsRecipe && <TextField
                    placeholder="Recipe name"
                    wrapperClassName="app-spirit-textfield-recipename"
                    value={this.state.recipeName}
                    onChange={this.handleRecipeNameChange}
                    required={true}
                />}
            </Flex>
        );
        var boardItemText = "board";
        var recipeNameText = (
            <li>
                {this.texts.saveThisRecipeExplanation}<br />
                So next time you want to generate a {this.texts.document}, you can simply select the recipe and all settings will be applied automatically.
            </li>
        );
        var shouldRenderApplyFilterCheckbox = this.state.mondayFilterText;
        //"Save this DocExport recipe for future use";
        if (this.state.dataSource === "SingleItem") {
            shouldRenderApplyFilterCheckbox = false;
            buttonContent = (
                <Button disabled={!this.state.recipeName} loading={this.state.executingSaveRecipe} onClick={this.createRecipeForIntegrationButtonClick}>
                    Next
                </Button>
            );
            saveAsRecipeContent = (
                <div>
                    <div className="app-spirit-little-space-bottom">The settings have to be saved as a {this.texts.DocExportRecipe} so that you can use them in your monday.com automation.</div>
                    <TextField
                        placeholder="Recipe name*"
                        wrapperClassName="app-spirit-textfield-recipename"
                        value={this.state.recipeName}
                        onChange={this.handleRecipeNameChange}
                        required={true}
                        requiredAsterisk={true}
                    />
                </div>
            );
            boardItemText = "item";
            recipeNameText = (
                <li>
                    This {this.texts.DocExportRecipe} has to be saved in order to use it in your monday.com automation.<br />
                    {this.texts.specifyRecipeNameExplanation}
                </li>
            );
        }

        return (
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-two-column">
                    <div className="monday-storybook-radio-buttons_wrapper-column">
                        <div>Output file</div>
                        {!this.props.isReportGenerator && <div>
                            <RadioButton text="PDF" name="radio-buttons-group-4" checked={this.state.documentType === 'Pdf'} onSelect={(e) => this.changeDocumentType('Pdf')} />
                            <RadioButton text="DOCX (Word document)" name="radio-buttons-group-4" checked={this.state.documentType === 'DocX'} onSelect={(e) => this.changeDocumentType('DocX')} />
                        </div>}
                        <TextField
                            placeholder="File name (optional)"
                            wrapperClassName="app-spirit-textfield-filename"
                            value={this.state.fileName}
                            onChange={this.handleFileNameChange}
                        />
                    </div>
                    
                    {this.state.dataSource === "Board" &&
                        <div>
                            <br />
                            <Tooltip content={this.texts.applyFilterToolTip}>
                                <Checkbox
                                    onChange={this.handleUseFilterCheckboxChange}
                                    disabled={!shouldRenderApplyFilterCheckbox}
                                    checked={shouldRenderApplyFilterCheckbox && this.state.useFilter}
                                    label="Apply board filter" />
                            </Tooltip>
                        </div>
                    }
                    
                    {/*<p><br /></p>*/}
                    {/*<Checkbox onChange={this.handleUseLetterpaperCheckboxChange} checked={this.state.useLetterpaper}*/}
                    {/*    label="Use letterpaper"*/}
                    {/*/>*/}
                    <br />
                    {saveAsRecipeContent}
                    <p><br /></p>
                    <Flex>
                        {buttonContent}
                        <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={this.closeAssistant}>
                            Cancel
                        </Button>
                    </Flex>
                </div>
                <div className="app-spirit-two-column">
                    <Box backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR} border={Box.borders.DEFAULT} padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}>
                        <p className="app-spirit-explanation-text-top">How to adjust the settings?</p>
                        <ol>
                            {!this.props.isReportGenerator && <li>Output file format: Decide whether you want to save your document as PDF or Word document (DOCX).</li>}
                            <li>File name: Enter a file name. If no name is entered, the name of the {boardItemText} is used.<br />
                                You can also use placeholders in the file name, e.g. Project report {"{{Today}}"}. This way the date will be added to your file name.</li>
                            {recipeNameText}
                        </ol>
                    </Box>
                </div>
            </Flex>
        );
    }
}