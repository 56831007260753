/*import { useContext } from 'react';*/
import React, { Component } from 'react';
import { Button } from "monday-ui-react-core";
import { IconButton, Link } from "monday-ui-react-core";
import { Delete } from "monday-ui-react-core/icons";
import axios from 'axios';
import { Table, TableCell, TableBody, TableHeader, TableHeaderCell, TableRow } from "monday-ui-react-core";
import { ChartDefinitionEdit } from './ChartDefinitionEdit';
import mondaySdk from "monday-sdk-js";
//import { MondayContext } from './MondayContext';

const monday = mondaySdk();
monday.setApiVersion("2023-10");

const TableEmptyState = () => <h1 style={{ textAlign: "center" }}>Empty State</h1>;

const TableErrorState = () => <h1 style={{ textAlign: "center" }}>Error State</h1>;

export class ChartDefinitions extends Component {
    static displayName = ChartDefinitions.name;
    //static contextType = MondayContext;

    constructor(props) {
        super(props);
        this.state = {
            chartDefinitions: [],
            //selectedChartDefinition: null,
            createNewChartDefinition: false,
            loading: true,
            isBoardOwner: props.isBoardOwner
        };
        this.mondayContext = window.mondayContext;
        this.towerAppInfos = window.towerAppInfos;
        //this.mondayContext = MondayContext; //useContext(MondayContext); //window.mondayContext;        
        //this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleClickCreateNewChartDefinition = this.handleClickCreateNewChartDefinition.bind(this);
        this.handleChartEditClose = this.handleChartEditClose.bind(this);
        this.handleUpgradeClick = this.handleUpgradeClick.bind(this);
    }

    componentDidMount() {
        //console.log(this.context);
        if (!this.towerAppInfos || this.towerAppInfos.hasCharts === false)
            return;
        this.populateChartDefinitionData();
    }

    handleChartEditClose() {
        this.setState({ createNewChartDefinition: false });
        this.populateChartDefinitionData();
    }

    renderNewTable(chartDefinitions) {

        var columns = [{
            id: "name",
            title: "Name",
            width: {
                min: 120,
                max: 2500
            },
            loadingStateType: "long-text"
        }, {
            id: "placeholder",
            title: "Placeholder",
            width: {
                min: 150,
                max: 2500
            },
            loadingStateType: "long-text"
        }, {
            id: "delete",
            title: "",
            width: 40,
            loadingStateType: "circle"
        }];

        var tableDataState = {
            isLoading: this.state.loading
        };

        return (
            <Table errorState={<TableErrorState />} emptyState={<TableEmptyState />} columns={columns} dataState={tableDataState}>
                <TableHeader>
                    {columns.map((headerCell, index) => <TableHeaderCell key={index} title={headerCell.title} />)}
                </TableHeader>
                <TableBody>
                    {chartDefinitions.map(rowItem => <TableRow key={rowItem.id}>
                        <TableCell>{rowItem.name}</TableCell>
                        <TableCell>{rowItem.placeholder}</TableCell>
                        <TableCell className="app-spirit-tablecell-with-button" >
                            {this.state.isBoardOwner && (
                                <IconButton
                                    ariaLabel="Delete"
                                    size={IconButton.sizes.SMALL}
                                    icon={Delete}
                                    onClick={(e) => this.deleteChartDefinition(e, rowItem.id)}
                                />
                            )}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        );
    }

    async deleteChartDefinition(event, id) {
        console.log('Delete chart definition ' + id);
        try {
            const response = await axios.delete('chartdefinition?Id=' + id + '&SessionToken=' + window.mondaySessionToken);
            console.log('Success:', response.data);
            await this.populateChartDefinitionData();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    handleClickCreateNewChartDefinition() {
        this.setState({ createNewChartDefinition: true });
    }

    handleUpgradeClick(event) {
        console.log('Upgrade now');
        event.preventDefault();
        // https://developer.monday.com/apps/docs/mondayexecute
        monday.execute('openPlanSelection', { isInPlanSelection: false });
    }

    render() {        
        if (!this.towerAppInfos || this.towerAppInfos.hasCharts === false) {
            return (<div>
                <span>Charts are only available for Enterprise accounts. Please upgrade!</span>
                <Button className="app-spirit-cancelbutton" onClick={this.handleUpgradeClick}>
                    Upgrade now
                </Button>
                <p></p>
                <a href="https://www.docexport.com/help/charts-reports/" target="_blank" rel="noreferrer">Learn more</a>
            </div>);
        }

        if (this.state.createNewChartDefinition) {
            return (
                <ChartDefinitionEdit onClose={this.handleChartEditClose}></ChartDefinitionEdit>
            );
        }
        let contents = this.renderNewTable(this.state.chartDefinitions);

        return (
            <div>
                <h3 id="tableLabel">Charts</h3>
                <Button onClick={this.handleClickCreateNewChartDefinition}>
                    Create new chart
                </Button>
                <p>                    
                </p>
                {contents}
                <p><Link href="https://www.docexport.com/help/template/charts/" text="Learn how charts work" />Hint: Charts can only be used in "Board level" templates.</p>
            </div>
        );
    }

    async populateChartDefinitionData() {
        const response = await fetch('chartdefinition/list?BoardId=' + this.mondayContext.boardId + '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        this.setState({ chartDefinitions: data, loading: false });
    }
}
