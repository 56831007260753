import React, { Component } from 'react';
import { Button, IconButton } from "monday-ui-react-core";
import { Delete } from "monday-ui-react-core/icons";
import axios from 'axios';
import { Table, TableCell, TableBody, TableHeader, TableHeaderCell, TableRow } from "monday-ui-react-core";
import { RecipeEdit } from './RecipeEdit';

const TableEmptyState = () => <h1 style={{ textAlign: "center" }}>Empty State</h1>;

const TableErrorState = () => <h1 style={{ textAlign: "center" }}>Error State</h1>;

export class Recipes extends Component {
    static displayName = Recipes.name;

    constructor(props) {
        super(props);
        this.state = {
            recipes: [],
            loading: true,
            recipeEditMode: false,
            recipeEditId: null,
            isBoardOwner: props.isBoardOwner
        };
        this.mondayContext = window.mondayContext;
        this.handleEditRecipe = this.handleEditRecipe.bind(this);
        this.handleRecipeEditClose = this.handleRecipeEditClose.bind(this);
    }

    componentDidMount() {
        this.populateRecipeData();
    }

    async handleEditRecipe(event, id) {
        this.setState({ recipeEditMode: true, recipeEditId: id });
    }

    renderNewTable(recipes) {
        var columns = [{
            id: "name",
            title: "Name",
            width: {
                min: 120,
                max: 500
            },
            loadingStateType: "long-text"
        }, {
            id: "dataSource",
            title: "Data",
            width: 100,
            loadingStateType: "medium-text"
        }, {
            id: "templateName",
            title: "Template",
            width: {
                min: 120,
                max: 2500
            },
            loadingStateType: "long-text"
        }, {
            id: "createdOn",
            title: "Date",
            width: 160,
            //infoContent: "Info content for the status column",
            loadingStateType: "medium-text"
        }, {
            id: "edit",
            title: "",
            width: 60,
            loadingStateType: "circle"
        }, {
            id: "delete",
            title: "",
            width: 40,
            loadingStateType: "circle"
        }];

        var tableDataState = {
            isLoading: this.state.loading
        };

        return (
            <Table errorState={<TableErrorState />} emptyState={<TableEmptyState />} columns={columns} dataState={tableDataState}>
                <TableHeader>
                    {columns.map((headerCell, index) => <TableHeaderCell key={index} title={headerCell.title} />)}
                </TableHeader>
                <TableBody>
                    {recipes.map(rowItem => <TableRow key={rowItem.id}>
                        <TableCell>{rowItem.name}</TableCell>
                        <TableCell>{rowItem.dataSource}</TableCell>
                        <TableCell>{rowItem.templateName}</TableCell>
                        <TableCell>{rowItem.createdOn}</TableCell>

                        <TableCell className="app-spirit-tablecell-with-button" >
                            {this.state.isBoardOwner && (
                                <Button
                                    kind={Button.kinds.SECONDARY}
                                    size={Button.sizes.SMALL}
                                    onClick={(e) => this.handleEditRecipe(e, rowItem.id)}>Edit</Button>
                            )}
                        </TableCell>

                        <TableCell className="app-spirit-tablecell-with-button" >
                            {this.state.isBoardOwner && (
                                <IconButton
                                    ariaLabel="Delete"
                                    size={IconButton.sizes.SMALL}
                                    icon={Delete}
                                    onClick={(e) => this.deleteRecipe(e, rowItem.id)}
                                />
                            )}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        );
    }

    async deleteRecipe(event, id) {
        console.log('Delete recipe ' + id);
        try {
            const response = await axios.delete('recipe?Id=' + id + '&SessionToken=' + window.mondaySessionToken);
            console.log('Success:', response.data);
            await this.populateRecipeData();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    handleRecipeEditClose() {
        this.setState({ recipeEditMode: false });
        this.populateRecipeData();
    }

    render() {
        if (this.state.recipeEditMode) {
            return (
                <div>
                    <RecipeEdit id={this.state.recipeEditId} isReportGenerator={this.props.isReportGenerator} onClose={this.handleRecipeEditClose}></RecipeEdit>
                </div>
            );
        }

        let contents = this.renderNewTable(this.state.recipes);

        return (
            <div>
                <h3 id="tableLabel">Recipes</h3>
                {contents}
            </div>
        );
    }

    async populateRecipeData() {
        var timeZoneOffset = this.mondayContext.user.timeZoneOffset;
        if (timeZoneOffset === undefined)
            timeZoneOffset = 0;
        const response = await fetch('recipe/list?BoardId=' + this.mondayContext.boardId +
            '&TimeZoneOffset=' + timeZoneOffset +
            '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        this.setState({ recipes: data, loading: false });
    }
}
