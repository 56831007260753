import React, { Component } from 'react';
import { Flex, TextField, Button, RadioButton } from "monday-ui-react-core";
import { EditableHeading, Tooltip } from "monday-ui-react-core";
import { Checkbox } from "monday-ui-react-core";
import { Dropdown } from "monday-ui-react-core";
import mondaySdk from "monday-sdk-js";
import axios from 'axios';
import DOMPurify from 'dompurify';
//import { convertMondayFilterToGraphQLFilter } from './MondayFilterHelper';

/*
TODO: Upload new template
TODO: Change filter condition
TODO: Attachment column
*/

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class RecipeEdit extends Component {
    static displayName = RecipeEdit.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            emailTemplatesAreSet: false,
            columnsAreSet: false,
            emailTemplates: [],
            id: '',
            recipeName: '',
            documentType: '',
            fileName: '',
            dataSource: '',
            fileToColumn: false,
            fileToColumnId: undefined,
            fileToColumns: [],
            hasNoFileToColumn: false,
            selectedFileToColumn: undefined,
            sendEmailToMe: false,
            sendEmailToMeTemplate: null,
            sendEmailToMeTemplateId: undefined,
            sendEmailToColumn: false,
            sendEmailToColumnId: undefined,
            sendEmailToColumnTemplate: null,
            sendEmailToColumnTemplateId: undefined,
            sendEmailColumns: [],
            hasNoSendEmailColumn: false,
            selectedSendEmailColumn: undefined,
            sendEmailToCustomAddresses: false,
            sendEmailToCustomAddressesTemplate: null,
            sendEmailToCustomAddressesTemplateId: undefined,
            customEmailAddresses: '',
        };

        this.texts = {
            sendEmailTemplate: 'Email template',
            fileToColumn: '',
            DocExport: 'DocExport',
            saveInApp: 'Save in DocExport view (Tab: Documents)',
            document: 'document',
            generatedDocumentsCanAlwaysBeFoundHere: "Generated documents can always be found here on the 'Documents' tab",
            sendToMeCheckboxText: 'Send via email to user who generates the document',
        }
        if (this.props.isReportGenerator) {
            this.texts.DocExport = 'Report Generator';
            this.texts.saveInApp = 'Save in Report Generator view (Tab: Reports)';
            this.texts.document = 'report';
            this.texts.generatedDocumentsCanAlwaysBeFoundHere = "Generated reports can always be found here on the 'Reports' tab";
            this.texts.sendToMeCheckboxText = 'Send via email to user who generates the report';
        }

        this.mondayContext = window.mondayContext;
        this.towerAppInfos = window.towerAppInfos;
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleRecipeNameChange = this.handleRecipeNameChange.bind(this);
        this.handleFileNameChange = this.handleFileNameChange.bind(this);
        this.handleFileToColumnChange = this.handleFileToColumnChange.bind(this);
        this.handleSendEmailToColumnChange = this.handleSendEmailToColumnChange.bind(this);
        this.handleFileToColumnCheckboxChange = this.handleFileToColumnCheckboxChange.bind(this);
        this.handleSendEmailToMeCheckboxChange = this.handleSendEmailToMeCheckboxChange.bind(this);
        this.handleSendEmailToColumnCheckboxChange = this.handleSendEmailToColumnCheckboxChange.bind(this);
        this.handleSendEmailToCustomAddressesCheckboxChange = this.handleSendEmailToCustomAddressesCheckboxChange.bind(this);
        this.handleSendEmailCustomAddressesChange = this.handleSendEmailCustomAddressesChange.bind(this);
        this.changeDocumentType = this.changeDocumentType.bind(this);
        this.handleSendEmailToMeTemplateChange = this.handleSendEmailToMeTemplateChange.bind(this);
        this.handleSendEmailToColumnTemplateChange = this.handleSendEmailToColumnTemplateChange.bind(this);
        this.handleSendEmailToCustomAddressesTemplateChange = this.handleSendEmailToCustomAddressesTemplateChange.bind(this);
    }

    componentDidMount() {
        this.loadColumnData();
        this.loadRecipe();
        if (this.towerAppInfos && this.towerAppInfos.hasEmailTemplates)
            this.populateEmailTemplateData();
    }

    async populateEmailTemplateData() {
        const response = await fetch('template/list?TemplateType=html&BoardId=' + this.mondayContext.boardId + '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        var emailTemplates = data.map(c => ({ value: c.id, label: c.name }));
        if (emailTemplates.length > 0)
            emailTemplates.unshift({ value: '', label: 'Default Template' });
        this.setState({ emailTemplates: emailTemplates }, this.checkAllDataIsSet);        
    }

    async loadRecipe() {
        try {
            const response = await axios.get(`recipe?id=${this.props.id}&sessionToken=${window.mondaySessionToken}`);            
            this.setState({
                isLoading: false,
                error: null,
                id: response.data.id,
                recipeName: response.data.recipeName,
                fileName: response.data.fileName,
                dataSource: response.data.dataSource,
                documentType: response.data.documentType,
                fileToColumn: response.data.fileToColumn,
                fileToColumnId: response.data.fileToColumnId,
                sendEmailToMe: response.data.sendEmailToMe,
                sendEmailToMeTemplateId: response.data.sendEmailToMeTemplateId,
                sendEmailToColumn: response.data.sendEmailToColumn,
                sendEmailToColumnId: response.data.sendEmailToColumnId,
                sendEmailToColumnTemplateId: response.data.sendEmailToColumnTemplateId,
                sendEmailToCustomAddresses: response.data.sendEmailToCustomAddresses,
                sendEmailToCustomAddressesTemplateId: response.data.sendEmailToCustomAddressesTemplateId,
                customEmailAddresses: response.data.emailCustomAddresses
            }, this.checkAllDataIsSet);
        } catch (error) {
            this.setState({ data: null, isLoading: false, error });
        }
    }

    checkAllDataIsSet() {
        if (this.state.isLoading)
            return;
        if (this.state.fileToColumns.length > 0 && !this.state.columnsAreSet) {
            var selectedFileToColumn = undefined;
            if (this.state.fileToColumnId)
                selectedFileToColumn = this.state.fileToColumns.find(c => c.value === this.state.fileToColumnId);
            var selectedSendEmailColumn = undefined;
            if (this.state.sendEmailToColumnId)
                selectedSendEmailColumn = this.state.sendEmailColumns.find(c => c.value === this.state.sendEmailToColumnId);
            this.setState({
                columnsAreSet: true,
                selectedFileToColumn: selectedFileToColumn,
                selectedSendEmailColumn: selectedSendEmailColumn
            });
        }
        if (this.state.emailTemplates.length > 0 && !this.state.emailTemplatesAreSet) {
            var sendEmailToMeTemplate = undefined;
            if (this.state.sendEmailToMeTemplateId)
                sendEmailToMeTemplate = this.state.emailTemplates.find(c => c.value === this.state.sendEmailToMeTemplateId);
            var sendEmailToColumnTemplate = undefined;
            if (this.state.sendEmailToColumnTemplateId)
                sendEmailToColumnTemplate = this.state.emailTemplates.find(c => c.value === this.state.sendEmailToColumnTemplateId);
            var sendEmailToCustomAddressesTemplate = undefined;
            if (this.state.sendEmailToCustomAddressesTemplateId)
                sendEmailToCustomAddressesTemplate = this.state.emailTemplates.find(c => c.value === this.state.sendEmailToCustomAddressesTemplateId);
            this.setState({
                sendEmailToMeTemplate: sendEmailToMeTemplate,
                sendEmailToColumnTemplate: sendEmailToColumnTemplate,
                sendEmailToCustomAddressesTemplate: sendEmailToCustomAddressesTemplate,
                emailTemplatesAreSet: true
            });
        }
    }    

    loadColumnData() {
        if (!this.mondayContext.connected) {
            this.mondayColumnData = {
                boards: [{
                    id: '1',
                    name: 'Board name',
                    columns: [{ id: 'files88', title: 'Dateispalte', type: 'file' }]
                }]
            };
            this.fileToColumnsRaw = [{ id: 'files88', title: 'Dateispalte', type: 'file' }];
            var fileToColumnsDemo = this.fileToColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                fileToColumns: fileToColumnsDemo
            });
            //this.setState({
            //    selectedFileToColumn: fileToColumnsDemo[0]
            //});

            this.sendEmailColumnsRaw = [{ id: 'e_mail', title: 'E-Mail', type: 'email' },
                { id: 'people', title: 'People', type: 'people' }];
            var sendEmailColumnsDemo = this.sendEmailColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                sendEmailColumns: sendEmailColumnsDemo
            }, this.checkAllDataIsSet);            
            return;
        }
        monday.api(this.GetColumnQuery(this.mondayContext.boardId), { apiVersion: '2023-10' }).then(columnData => {
            //console.log(columnData);
            this.mondayColumnData = columnData.data;
            this.fileToColumnsRaw = columnData.data.boards[0].columns.filter(c => c.type === 'file');
            var fileToColumns = this.fileToColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                fileToColumns: fileToColumns
            });
            // Show error when 0 file columns exist
            if (fileToColumns.length === 0) {
                this.setState({
                    hasNoFileToColumn: true
                });
            }
            //// If only 1 file column exists -> select it
            //if (fileToColumns.length === 1) {
            //    this.setState({
            //        selectedFileToColumn: fileToColumns[0]
            //    });
            //}

            this.sendEmailColumnsRaw = columnData.data.boards[0].columns.filter(c => c.type === 'email' // Nach Rücksprache mit CH am 25.11.2023 raus: || c.type === 'text');
                || c.type === 'people');
            var sendEmailColumns = this.sendEmailColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                sendEmailColumns: sendEmailColumns
            }, this.checkAllDataIsSet);
            // Show error when 0 file columns exist
            if (sendEmailColumns.length === 0) {
                this.setState({
                    hasNoSendEmailColumn: true
                });
            }
        //    // If only 1 file column exists -> select it
        //    if (sendEmailColumns.length === 1) {
        //        this.setState({
        //            selectedSendEmailColumn: sendEmailColumns[0]
        //        });
            //    }
            this.checkAllDataIsSet();
        });
    }

    GetColumnQuery(boardId) {
        return `
query {
  complexity {
    query
    after
  }
  boards (ids: ${boardId}) { 
    id      
    name    
    columns {
        id
        title
        type
        settings_str
    }
  }
        }`;
    }

    handleRecipeNameChange(text) {
        this.setState({ recipeName: text });
    }

    handleFileNameChange(text) {
        this.setState({ fileName: text });
    }

    handleCancelClick() {
        this.props.onClose();
    }

    async handleClickSave() {
        this.setState({ isSaving: true });
        const data = this.getDataForPOST();
        try {
            const response = await axios.post('recipe/save', data);
            console.log(response.data);
            if (response.data.result) {
                this.setState({ isSaving: false });
                this.props.onClose();
            } else {
                var errorMessage = 'Error saving recipe: ' + response.data.error;
                console.log(errorMessage);
                alert(errorMessage);
                this.setState({ isSaving: false });
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error);
            this.setState({ isSaving: false });
        }
    }

    getDataForPOST() {
        var sendEmailColumnId = '';
        if (this.state.sendEmailToColumn)
            sendEmailColumnId = this.state.selectedSendEmailColumn?.value;
        var sendEmailCustomAddresses = '';
        if (this.state.sendEmailToCustomAddresses)
            sendEmailCustomAddresses = this.state.customEmailAddresses;
        const data = {
            id: this.state.id,
            boardId: this.mondayContext.boardId,
            sessionToken: window.mondaySessionToken,
            recipeName: DOMPurify.sanitize(this.state.recipeName),
            fileName: DOMPurify.sanitize(this.state.fileName),
            documentType: DOMPurify.sanitize(this.state.documentType),
            fileToColumn: this.state.fileToColumn,
            fileToColumnId: DOMPurify.sanitize(this.state.selectedFileToColumn?.value),
            fileToColumnName: DOMPurify.sanitize(this.state.selectedFileToColumn?.label),
            sendEmailToMe: this.state.sendEmailToMe,
            sendEmailToColumn: this.state.sendEmailToColumn,
            sendEmailToColumnId: DOMPurify.sanitize(sendEmailColumnId),
            sendEmailToColumnName: DOMPurify.sanitize(this.state.selectedSendEmailColumn?.label),
            sendEmailToCustomAddresses: this.state.sendEmailToCustomAddresses,
            emailCustomAddresses: DOMPurify.sanitize(sendEmailCustomAddresses),
            sendEmailToMeTemplateId: DOMPurify.sanitize(this.state.sendEmailToMeTemplate?.value),
            sendEmailToColumnTemplateId: DOMPurify.sanitize(this.state.sendEmailToColumnTemplate?.value),
            sendEmailToCustomAddressesTemplateId: DOMPurify.sanitize(this.state.sendEmailToCustomAddressesTemplate?.value),
        };
        //if (this.state.useFilter && this.state.mondayFilterText) {
        //    data.dataFilter = this.state.mondayFilterText;
        //}
        return data;
    }

    renderSelectActionsStep() {
        var actions = undefined;
        var sendEmailAction = undefined;
        if (this.state.dataSource === "SingleItem") {
            let fileToColumnSelect = this.renderFileToColumnSelect();
            actions = (
                <Flex className="app-spirit-flex">
                    <Checkbox className="app-spirit-checkbox" disabled={this.state.hasNoFileToColumn} onChange={this.handleFileToColumnCheckboxChange} checked={this.state.fileToColumn}
                        label="Save to a file column:"
                    />
                    {fileToColumnSelect}
                </Flex>
            );
            let sendEmailToColumnSelect = this.renderSendEmailToColumnSelect();
            let sendEmailToColumnTemplateSelect = this.renderSendEmailToColumnTemplateSelect();
            sendEmailAction = (
                <Flex className="app-spirit-flex">
                    <Checkbox className="app-spirit-checkbox" disabled={this.state.hasNoSendEmailColumn} onChange={this.handleSendEmailToColumnCheckboxChange} checked={this.state.sendEmailToColumn}
                        label="Send via email to email address(es) in column:"
                    />
                    {sendEmailToColumnSelect}
                    {sendEmailToColumnTemplateSelect}
                </Flex>
            );
        }

        var sendEmailToMeTemplateSelect = this.renderSendEmailToMeTemplateSelect();
        var sendEmailToCustomAddressesInput = this.renderSendEmailToCustomAddressesInput();
        var sendEmailToCustomAddressesTemplateSelect = this.renderSendEmailToCustomAddressesTemplateSelect();
        return (
            <div>
                <p>What should happen with the {this.texts.document} after creating it?</p>
                <div title={this.texts.generatedDocumentsCanAlwaysBeFoundHere}>
                    <Checkbox onChange={this.handleCheckboxChange} checked={true} disabled={true}
                        label={this.texts.saveInApp}
                    />
                </div>
                {actions}
                <Flex>
                    <Checkbox className="app-spirit-checkbox" onChange={this.handleSendEmailToMeCheckboxChange} checked={this.state.sendEmailToMe}
                        label={this.texts.sendToMeCheckboxText}
                    />
                    {sendEmailToMeTemplateSelect}
                </Flex>
                {sendEmailAction}
                <Checkbox className="app-spirit-checkbox" onChange={this.handleSendEmailToCustomAddressesCheckboxChange} checked={this.state.sendEmailToCustomAddresses}
                    label="Send via email to email address(es)"
                />
                <Flex className="app-spirit-flex">
                    {sendEmailToCustomAddressesInput}
                    {sendEmailToCustomAddressesTemplateSelect}
                </Flex>
            </div>
        );
    }

    renderSendEmailToMeTemplateSelect() {
        if (!this.state.sendEmailToMe)
            return undefined;
        if (!this.state.emailTemplates)
            return undefined;
        if (this.state.emailTemplates.length === 0)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleSendEmailToMeTemplateChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.sendEmailToMeTemplate}
                options={this.state.emailTemplates}
                clearable={false}
                placeholder={this.texts.sendEmailTemplate}
            />
        );
    }

    renderSendEmailToColumnTemplateSelect() {
        if (!this.state.sendEmailToColumn)
            return undefined;
        if (!this.state.emailTemplates)
            return undefined;
        if (this.state.emailTemplates.length === 0)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleSendEmailToColumnTemplateChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.sendEmailToColumnTemplate}
                options={this.state.emailTemplates}
                clearable={false}
                placeholder={this.texts.sendEmailTemplate}
            />
        );
    }

    renderSendEmailToCustomAddressesTemplateSelect() {
        if (!this.state.sendEmailToCustomAddresses)
            return undefined;
        if (!this.state.emailTemplates)
            return undefined;
        if (this.state.emailTemplates.length === 0)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleSendEmailToCustomAddressesTemplateChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.sendEmailToCustomAddressesTemplate}
                options={this.state.emailTemplates}
                clearable={false}
                placeholder={this.texts.sendEmailTemplate}
            />
        );
    }

    renderSendEmailToCustomAddressesInput() {
        if (!this.state.sendEmailToCustomAddresses)
            return undefined;
        return (
            <TextField
                wrapperClassName="app-spirit-textfield-emailaddresses"
                placeholder="Email addresses separated by semicolon"
                value={this.state.customEmailAddresses}                
                onChange={this.handleSendEmailCustomAddressesChange}
            />
        );
    }

    changeDocumentType(documentType) {
        this.setState({ documentType: documentType });
    }

    handleFileToColumnCheckboxChange(event) {
        //console.log(this.state);
        //console.log(this.state.fileToColumn);
        this.setState({ fileToColumn: !this.state.fileToColumn });
    }

    handleSendEmailToMeCheckboxChange(event) {
        //console.log(this.state);
        //console.log(this.state.sendEmailToMe);
        this.setState({ sendEmailToMe: !this.state.sendEmailToMe });
    }

    handleSendEmailToColumnCheckboxChange(event) {
        //console.log(this.state);
        //console.log(this.state.sendEmailToColumn);
        this.setState({ sendEmailToColumn: !this.state.sendEmailToColumn });
    }

    handleSendEmailToCustomAddressesCheckboxChange(event) {
        //console.log(this.state);
        //console.log(this.state.sendEmailToCustomAddresses);
        this.setState({ sendEmailToCustomAddresses: !this.state.sendEmailToCustomAddresses });
    }

    handleSendEmailCustomAddressesChange(text) {
        this.setState({ customEmailAddresses: text });
    }

    handleFileToColumnChange(selectedFileToColumn) {
        this.setState({ ...this.state, selectedFileToColumn: selectedFileToColumn });
        //monday.storage.instance.setItem('FileToColumn', selectedFileToColumn.value);
    }

    handleSendEmailToColumnChange(selectedSendEmailColumn) {
        this.setState({ ...this.state, selectedSendEmailColumn: selectedSendEmailColumn });
        //monday.storage.instance.setItem('FileToColumn', selectedFileToColumn.value);
    }

    handleSendEmailToMeTemplateChange(sendEmailToMeTemplate) {
        this.setState({ ...this.state, sendEmailToMeTemplate: sendEmailToMeTemplate });
    }

    handleSendEmailToColumnTemplateChange(sendEmailToColumnTemplate) {
        this.setState({ ...this.state, sendEmailToColumnTemplate: sendEmailToColumnTemplate });
    }

    handleSendEmailToCustomAddressesTemplateChange(sendEmailToCustomAddressesTemplate) {
        this.setState({ ...this.state, sendEmailToCustomAddressesTemplate: sendEmailToCustomAddressesTemplate });
    }

    renderSendEmailToColumnSelect() {
        //if (!this.state.sendEmailToColumn)
        //    return undefined;
        if (!this.state.sendEmailColumns)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleSendEmailToColumnChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.selectedSendEmailColumn}
                options={this.state.sendEmailColumns}
                clearable={false}
                placeholder={this.texts.sendEmailToColumn}
            />
        );
    }

    renderFileToColumnSelect() {
        //if (!this.state.fileToColumn)
        //    return undefined;
        if (!this.state.fileToColumns)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleFileToColumnChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.selectedFileToColumn}
                options={this.state.fileToColumns}
                clearable={false}
                placeholder={this.texts.fileToColumn}
            />
        );
    }

    render() {
        if (this.state.isLoading)  // TODO: Loading animation
            return (<p><em>Loading...</em></p>);

        const actions = this.renderSelectActionsStep();
        return (
            <div>
                <Tooltip content="Click to Edit">
                    <EditableHeading
                        onChange={this.handleRecipeNameChange}
                        type="h2"
                        value={this.state.recipeName}
                    />
                </Tooltip>
                <p></p>
                {actions}
                <p></p>
                <h2>More settings</h2>
                <div>Output file</div>
                {!this.props.isReportGenerator && <div>
                    <RadioButton text="PDF" name="radio-buttons-group-4" checked={this.state.documentType === 'Pdf'} onSelect={(e) => this.changeDocumentType('Pdf')} />
                    <RadioButton text="DOCX (Word document)" name="radio-buttons-group-4" checked={this.state.documentType === 'DocX'} onSelect={(e) => this.changeDocumentType('DocX')} />
                </div>}
                <TextField
                    placeholder="File name (optional)"
                    wrapperClassName="app-spirit-textfield-filename"
                    value={this.state.fileName}
                    onChange={this.handleFileNameChange}
                />
                <p><br /></p>
                <Button onClick={this.handleClickSave}>Save</Button>
                <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={this.handleCancelClick}>Cancel</Button>
            </div>
        );
    }
}